import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const place_order = createAsyncThunk(
  "order/place_order",
  async ({
    price,
    products,
    shipping_fee,
    shippingInfo,
    userId,
    navigate,
    items,
  }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/home/order/place-order", {
        price,
        products,
        shipping_fee,
        shippingInfo,
        userId,
        navigate,
        items,
      });
      navigate("/payment", {
        state: {
          price: price + shipping_fee,
          items,
          orderId: data.idorders,
        },
      });
      return true;
    } catch (error) {}
  }
);

export const get_orders = createAsyncThunk(
  "order/get_orders",
  async ({ customerId, status }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/home/customer/get-orders/${customerId}/${status}`
      );
      return fulfillWithValue(data);
    } catch (error) {}
  }
);

export const get_order = createAsyncThunk(
  "order/get_order",
  async (orderId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/home/customer/get-order/${orderId}`);
      return fulfillWithValue(data);
    } catch (error) {}
  }
);

export const submit_return_request = createAsyncThunk(
  "order/submit_return_request",
  async (
    {
      orderId,
      selectedItems,
      returnReason,
      shippingInfo,
      customerId,
      totalReturnAmount,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/home/order/return-request", {
        orderId,
        selectedItems,
        returnReason,
        shippingInfo,
        customerId,
        totalReturnAmount,
      });

      // Assuming the backend returns some data upon successful submission
      return response.data;
    } catch (error) {
      // Handle error or rejection      
      return rejectWithValue(error.message);
    }
  }
);
export const get_return = createAsyncThunk(
  "order/get_return",
  async (orderId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/home/customer/get-return/${orderId}`);
      return fulfillWithValue(data);
    } catch (error) {}
  }
);

export const orderReducer = createSlice({
  name: "order",
  initialState: {
    loader: false,
    myOrders: [],
    errorMessage: "",
    successMessage: "",
    myOrder: {},
    returnProducts: [],
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(place_order.pending, (state, _) => {
      state.loader = true;
    })
    .addCase(place_order.rejected, (state, { payload }) => {
      state.errorMessage = payload.error;
      state.loader = false;
    })
    .addCase(place_order.fulfilled, (state, { payload }) => {      
      state.successMessage = payload.message;
      state.loader = false;      
    })
      .addCase(get_orders.fulfilled, (state, { payload }) => {
        state.myOrders = payload.orders;
      })
      .addCase(get_order.fulfilled, (state, { payload }) => {
        state.myOrder = payload.order;
      })
      .addCase(submit_return_request.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
      })
      .addCase(submit_return_request.rejected, (state, { payload }) => {
        state.errorMessage = payload.error;
      })
      .addCase(get_return.fulfilled, (state, { payload }) => {
        if (payload) {
          state.returnProducts = payload.products;
          // Combine productIds with productStatus
          // const returnProducts = payload.productIds.map((productId, index) => ({
          //     productId,
          //     returnStatus: payload.productStatus[index]
          // }));
          // state.returnProducts = returnProducts;
        } else {
          state.returnProducts = [];
        }
      });

    // if (payload && payload.products.productIds) {
    //     state.returnProducts = payload.products;
    //   } else {
    //     state.returnProducts = []; // Set returnProducts to an empty array if payload or payload.productIds is null
    //   }
  },
});

export const { messageClear } = orderReducer.actions;
export default orderReducer.reducer;
