import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";
export const get_category = createAsyncThunk(
  "product/get_category",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/home/get-categories");
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_products = createAsyncThunk(
  "product/get_products",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/home/get-products");
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_product = createAsyncThunk(
  "product/get_product",
  async (slug, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/home/get-product/${slug}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const price_range_product = createAsyncThunk(
  "product/price_range_product",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/home/price-range-latest-product");
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const query_products = createAsyncThunk(
  "product/query_products",
  async (query, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/home/query-products?category=${query.category}&&rating=${
          query.rating
        }&&lowPrice=${query.low}&&highPrice=${query.high}&&sortPrice=${
          query.sortPrice
        }&&pageNumber=${query.pageNumber}&&searchValue=${
          query.searchValue ? query.searchValue : ""
        }`
      );
      return fulfillWithValue(data);
    } catch (error) {}
  }
);

export const customer_review = createAsyncThunk(
  "review/customer_review",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/home/customer/submit-review", info);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_reviews = createAsyncThunk(
  "review/get_reviews",
  async ({ productId, pageNumber }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/home/customer/get-reviews/${productId}?pageNo=${pageNumber}`
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_banners = createAsyncThunk(
  "product/get_banners",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/banner");
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const get_bannerproducts = createAsyncThunk(
  "product/get_bannerproducts",
  async (bannerId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/banner/get-products-by-banner/${bannerId}`
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const ask_question = createAsyncThunk(
  "question/ask_question",
  async (questionData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(
        "/home/customer/submit-question",
        questionData
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
      // Handle error if needed
    }
  }
);

export const get_questions = createAsyncThunk(
  "question/get_questions",
  async ({ productId, pageNumber }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/home/customer/get-questions/${productId}?pageNo=${pageNumber}`
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
      // Handle error if needed
    }
  }
);

export const homeReducer = createSlice({
  name: "home",
  initialState: {
    categories: [],
    products: [],
    totalProduct: 0,
    perPage: 4,
    latestProduct: [],
    topRatedProduct: [],
    discountProduct: [],
    priceRange: {
      low: 0,
      high: 100,
    },
    product: {},
    relatedProducts: [],
    moreProducts: [],
    totalReview: 0,
    rating_review: [],
    reviews: [],
    questions: [],
    totalQuestions: 0,
    buyBox: [],
    banners: [],
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    messageClear: (state, _) => {
      state.successMessage = "";
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_category.fulfilled, (state, { payload }) => {
        state.categories = payload.categories;
      })
      .addCase(get_products.fulfilled, (state, { payload }) => {
        state.products = payload.products;
        state.latestProduct = payload.latestProduct;
        state.topRatedProduct = payload.topRatedProduct;
        state.discountProduct = payload.discountProduct;
      })
      .addCase(get_product.fulfilled, (state, { payload }) => {
        state.product = payload.product;
        state.relatedProducts = payload.relatedProducts;
        state.moreProducts = payload.moreProducts;
        state.buyBox = payload.buyBox;
      })
      .addCase(price_range_product.fulfilled, (state, { payload }) => {
        state.latestProduct = payload.latestProduct;
        state.priceRange = payload.priceRange;
      })
      .addCase(query_products.fulfilled, (state, { payload }) => {
        state.products = payload.products;
        state.totalProduct = payload.totalProduct;
        state.perPage = payload.perPage;
      })
      .addCase(customer_review.rejected, (state, action) => {
        const errorMessage =
          action.error && action.error.message
            ? action.error.message
            : "An error occurred";
        state.errorMessage = errorMessage;
      })
      .addCase(customer_review.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.errorMessage = payload.message;
      })
      .addCase(get_reviews.fulfilled, (state, { payload }) => {
        state.reviews = payload.reviews;
        state.totalReview = payload.totalReview;
        state.rating_review = payload.rating_review;
      })
      .addCase(ask_question.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.errorMessage = payload.message;
      })
      .addCase(get_questions.fulfilled, (state, { payload }) => {
        state.questions = payload.questions;
        state.totalQuestions = payload.totalQuestions;
      })
      .addCase(get_banners.fulfilled, (state, { payload }) => {
        state.banners = payload.banners;
      })
      .addCase(get_bannerproducts.fulfilled, (state, { payload }) => {
        state.products = payload.products;
      });
  },
});
export const { messageClear } = homeReducer.actions;
export default homeReducer.reducer;
