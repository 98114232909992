import React, { useEffect } from "react";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Ratings from "../Ratings";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import {
  add_to_cart,
  messageClear,
  add_to_wishlist,
} from "../../store/reducers/cartReducer";

const FeaturedProducts = ({ products }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage, errorMessage } = useSelector((state) => state.cart);

  const add_cart = (id) => {
    if (userInfo) {
      dispatch(
        add_to_cart({
          userId: userInfo.id,
          quantity: 1,
          inventoryId: id,
        })
      );
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        inventoryId: pro.inventoryId,
        name: pro.productName,
        price: pro.offerPrice,
        image: pro.images,
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };

  const cleanHTML = (dirty) => ({
    __html: DOMPurify.sanitize(dirty),
  });

  return (
    <div className="w-[85%] flex flex-wrap mx-auto">
      <div className="w-full">
        <div className="text-center flex justify-center items-center flex-col text-4xl text-black font-bold relative pb-[45px]">
          <h2>Featured Products</h2>
          <div className="w-[100px] h-[4px] bg-[#7fad39] mt-4"></div>
        </div>
      </div>
      <div className="w-full grid grid-cols-4 md-lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6">
        {products.map((p, i) => (
          <div
            key={i}
            className="border group transition-all duration-500 hover:shadow-md hover:-mt-3 rounded-md"
          >
            <Helmet>
              <link
                rel="canonical"
                href={`https://www.gorudh.com/product/details/${p.slug}`}
              />
            </Helmet>
            <div className="relative overflow-hidden">
              {parseInt(p.discount, 10) ? (
                <div className="flex justify-center items-center absolute text-blue-800 w-[55px] h-[55px] rounded-full bg-yellow-500 font-bold text-m left-2 top-2 p-4">
                  {parseFloat(p.discount).toFixed(0)}% Off
                </div>
              ) : (
                ""
              )}

              <Link to={`/product/details/${p.slug}`} className="block">
                <img
                  className="sm:w-full w-full h-[350px] object-contain object-center"
                  src={DOMPurify.sanitize(p.images.split(",")[0])}
                  alt="product image"
                />
              </Link>
              <ul className="flex transition-all duration-700 -bottom-10 justify-center items-center gap-2 absolute w-full group-hover:bottom-3">
                <li
                  onClick={() => add_wishlist(p)}
                  className="w-[38px] h-[38px] cursor-pointer bg-slate-200 flex justify-center items-center rounded-full hover:bg-[#ff5555] hover:text-white hover:rotate-[720deg] transition-all"
                  aria-label="Add to wishlist"
                >
                  <AiFillHeart />
                </li>
                <li>
                  <Link
                    to={`/product/details/${p.slug}`}
                    className="w-[38px] h-[38px] cursor-pointer bg-slate-200 flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                    aria-label="View product"
                  >
                    <FaEye />
                  </Link>
                </li>
                <li
                  onClick={() => add_cart(p.inventoryId)}
                  className="w-[38px] h-[38px] cursor-pointer bg-slate-200 flex justify-center items-center rounded-full hover:bg-[#ffee53] hover:text-blue-700 hover:rotate-[720deg] transition-all"
                  aria-label="Add to cart"
                >
                  <AiOutlineShoppingCart />
                </li>
              </ul>
            </div>
            <div className="py-3 text-black px-2">
              <Link to={`/product/details/${p.slug}`} className="block" aria-label={`View details of ${p.productName}`}>
                <h2 className="h-[70%] text-lg font-semibold text-slate-800">
                  {DOMPurify.sanitize(p.productName)}
                </h2>
                <span className="mt-2">Brand: {DOMPurify.sanitize(p.brand)}</span>
                <div className="mt-2 flex">
                  {p.rating && (
                    <>
                      <Ratings ratings={p.rating} />
                      <span style={{ fontWeight: "bold" }}>({p.rating})</span>
                    </>
                  )}
                </div>
                <div className="flex justify-start items-center gap-3">
                  <span className="mt-2 text-lg font-bold">
                    ₹{DOMPurify.sanitize(p.sellingPrice.toString())}
                  </span>
                </div>
                <span>M.R.P: </span>
                <span className="text-lg font-bold text-red-700 line-through">
                  ₹{DOMPurify.sanitize(p.mrp.toString())}
                </span>
                {p.stock > 0 ? (
                  <span className="px-2 py-1 border border-slate-400 bg-green-500 rounded-md ml-10">
                    Available
                  </span>
                ) : (
                  <span className="px-2 py-1 border border-slate-400 bg-red-500 rounded-md ml-6">
                    Out Of Stock
                  </span>
                )}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducts;
