import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';


export const fetchAdminResponse = createAsyncThunk(
    'chatbot/fetchAdminResponse',
    async (userMessage, { fulfillWithValue, rejectWithValue }) => {
        // Implement your API call here
        // Example:
        // try {
        //     const { data } = await api.post('/admin/response', { userMessage });
        //     return fulfillWithValue(data);
        // } catch (error) {
        //     return rejectWithValue(error.response.data);
        // }
    }
);

const chatbotSlice = createSlice({
    name: 'chatbot',
    initialState: {
        loader: false,
        userMessages: [],
        adminResponses: [],
    },
    reducers: {
        addUserMessage: (state, action) => {
            state.userMessages.push(action.payload);
        },
        addAdminResponse: (state, action) => {
            state.adminResponses.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAdminResponse.pending, (state, action) => {
            // Handle pending state, for example, show a loader
            state.loader = true;
        })
        .addCase(fetchAdminResponse.fulfilled, (state, action) => {
            state.adminResponses.push(action.payload);
        })
        .addCase(fetchAdminResponse.rejected, (state, action) => {
            // Handle rejected state, for example, show an error message
        });
    },
});

export const { addUserMessage, addAdminResponse } = chatbotSlice.actions;
export default chatbotSlice.reducer;