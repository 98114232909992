import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { get_group } from "../store/reducers/catalogueReducer";
import { trackEvent } from "../utils/ga";

const CatalogueIndex = () => {
  const { brand, model, year, variant, subVariant } = useParams();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(get_group({ brand, model, year, variant, subVariant }));
  }, [dispatch, brand, model, year, variant, subVariant]);

  const { groups } = useSelector((state) => state.catalogue);

  const handleCatalogueItemClick = (itemId, itemName, brand, model, year, variant, subVariant) => {
    trackEvent(
      "Catalogue",
      "View Item",
      `Item ID: ${itemId}, Item Name: ${itemName}, Item Brand: ${brand},Item Model: ${model}, Item Year: ${year}, Item Variant: ${variant}, Item Subvariant: ${subVariant}`
    );
  };

  return (
    <div>
      <Headers />
      <h1 className="font-bold text-black">Catalogue Index</h1>
      <table className="border border-collapse border-gray-800 rounded-md">
        <tbody>
          <tr>
            <td className="border border-gray-800 px-4 py-2 font-bold">
              Manufacturer
            </td>
            <td className="border border-gray-800 px-4 py-2">{brand}</td>
            <td className="border border-gray-800 px-4 py-2 font-bold">
              Model
            </td>
            <td className="border border-gray-800 px-4 py-2">{model}</td>
            <td className="border border-gray-800 px-4 py-2 font-bold">Year</td>
            <td className="border border-gray-800 px-4 py-2">{year}</td>
            <td className="border border-gray-800 px-4 py-2 font-bold">
              Variant
            </td>
            <td className="border border-gray-800 px-4 py-2">{variant}</td>
            <td className="border border-gray-800 px-4 py-2 font-bold">
              Sub-Variant
            </td>
            <td className="border border-gray-800 px-4 py-2">{subVariant}</td>
          </tr>
        </tbody>
      </table>

      {Object.keys(groups).map((groupName) => (
        <div key={groupName}>
          <h2 className="border border-slate-500 font-bold mt-2 bg-emerald-500 rounded-md text-center">
            {groupName}
          </h2>
          <div className="flex flex-wrap">
            {groups[groupName].map((item) => (
              <div
                key={item.idcatalogue}
                className="w-1/4 p-4 border border-slate-500 mx-2 my-2 rounded-md"
              >
                <Link
                  to={`/cataloguePage/${brand}/${model}/${year}/${variant}/${subVariant}/${item.idcatalogue}`}
                  onClick={() => handleCatalogueItemClick(item.idcatalogue, item.title, item.brand, item.model, item.year, item.variant, item.subVariant )}
                >
                  <h3 className="border border-slate-500 bg-yellow-400 inline-block p-2 mx-auto font-semibold text-gray-600 rounded-md">
                    {" "}
                    {item.title}{" "}
                  </h3>{" "}
                  {/* Add title here */}
                  <img
                    src={item.image}
                    alt={`Image for ${groupName}`}
                    className="w-full"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default CatalogueIndex;
