import axios from "axios";
const local = 'http://localhost:5000';
const production = 'https://backend.gorudh.com';

let api_url = '';
let mode = 'PRO';

if (mode==='PRO'){
  api_url = production;
}
else{
  api_url = local;
}
const api = axios.create({
  baseURL: `${api_url}/api`,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  if (config.headers["Content-Type"] === "multipart/form-data") {
    // Allow axios to set the correct Content-Type for FormData
    delete config.headers["Content-Type"];
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Network Error:", error);
    return Promise.reject(error);
  }
);

export default api;
