import React, { useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { place_order } from "../store/reducers/orderReducer";
import FadeLoader from 'react-spinners/FadeLoader'

const Shipping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.order);
  const {
    state: { products, price, shipping_fee, items },
  } = useLocation();  
  const [res, setRes] = useState(false);
  const [state, setState] = useState({
    name: "",
    mobilenumber: "",
    address: "",
    district: "",
    area: "",
    city: "",
    utstate: "",
    pin: "",
    landmark: "",
  });
  const save = (e) => {
    e.preventDefault();
    const {name,mobilenumber,address,district,area,city,utstate,pin,landmark,} = state;
    if (name &&mobilenumber &&address &&district &&area &&city &&utstate &&pin &&landmark) 
    {
      setRes(true);
    } else {
      // Handle case where any of the required fields are missing
      alert("Please fill in all required fields.");
    }
  };
  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  
  const placeOrder = () => {
    dispatch(
      place_order({
        price,
        products,
        shipping_fee,
        shippingInfo: state,
        userId: userInfo.id,
        navigate,
        items,
      })
    );
  };
  return (
    <div>
            {
                loader && <div className='w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]'>
                    <FadeLoader />
                </div>
            }
      <Headers />
      <section className='mt-6 bg-cover bg-no-repeat relative bg-left'>
        <div className="absolute left-0 top-0 w-full h-full bg-[#2422228a]">
          <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex flex-col justify-center gap-1 items-center h-full w-full text-white">
              <h2 className="text-3xl font-bold">Jacked.com</h2>
              <div className="flex justify-center items-center gap-2 text-2xl w-full">
                <Link to="/">Home</Link>
                <span className="pt-2">
                  <MdOutlineKeyboardArrowRight />
                </span>
                <span>Place Order</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#eeeeee]">
        <div className="w-[85%] lg:w-[90%] md:w-[90%] sm:w-[90] mx-auto py-16">
          <div className="w-full flex flex-wrap">
            <div className="w-[67%] md-lg:w-full">
              <div className="flex flex-col gap-3">
                <div className="bg-white p-6 shadow-sm rounded-md">
                  {!res && (
                    <>
                      <h2 className="text-slate-600 font-bold pb-3">
                        Add Your Shipping Information
                      </h2>
                      <form onSubmit={save}>
                        <div className="flex md:flex-col md:gap-2 w-full gap-5 text-slate-600">
                          <div className="flex flex-col gap-1 mb-2 w-full">
                            <label htmlFor="name">Name</label>
                            <input
                              onChange={inputHandle}
                              value={state.name}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="name"
                              placeholder="Name"
                              id="name"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2 w-full">
                            <label htmlFor="address">Address</label>
                            <input
                              onChange={inputHandle}
                              value={state.address}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="address"
                              placeholder="House no / building / street"
                              id="address"
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-col md:gap-2 w-full gap-5 text-slate-600">
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="area">Area</label>
                            <input
                              onChange={inputHandle}
                              value={state.area}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="area"
                              placeholder="Area"
                              id="area"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="district">District</label>
                            <input
                              onChange={inputHandle}
                              value={state.district}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="district"
                              placeholder="District"
                              id="district"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="city">City</label>
                            <input
                              onChange={inputHandle}
                              value={state.city}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="city"
                              placeholder="City"
                              id="city"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="utstate">Union Territory / State</label>
                            <input
                              onChange={inputHandle}
                              value={state.utstate}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="utstate"
                              placeholder="State"
                              id="utstate"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="pin">PIN</label>
                            <input
                              onChange={inputHandle}
                              value={state.pin}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="pin"
                              placeholder="PIN"
                              id="pin"
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-col md:gap-2 w-full gap-5 text-slate-600">
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="landmark">Landmark</label>
                            <input
                              onChange={inputHandle}
                              value={state.landmark}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="landmark"
                              placeholder="Landmark"
                              id="landmark"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mb-2">
                            <label htmlFor="mobilenumber">Mobile Number</label>
                            <input
                              onChange={inputHandle}
                              value={state.mobilenumber}
                              type="text"
                              className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                              name="mobilenumber"
                              placeholder="Mobile Number"
                              id="mobilenumber"
                            />
                          </div>
                          <div className="flex flex-col gap-1 mt-7 w-full">
                            <button className="px-3 py-[8px] rounded-full hover:shadow-indigo-500/20 hover:shadow-lg bg-yellow-500 text-white font-bold">
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="flex md:flex-col md:gap-2 w-full gap-5 text-slate-600"></div>
                      </form>
                    </>
                  )}
                  {res && (
  <div className="flex flex-col gap-1 bg-yellow-100 p-4 rounded-md">
    <h2 className="text-black font-bold pb-2 flex justify-between">
  <span>Delivery Address</span>
  <span
    onClick={() => setRes(false)}
    className="inline-block px-1 py-1 bg-indigo-500 text-white rounded cursor-pointer"
  >
    Change
  </span>
</h2>

    
    <p>
    <span className="text-black font-bold text-sm">
        Name: {" "}
      </span>
      <span className="text-black font-bold text-sm">
        {state.name}{" "}
      </span>
    </p>
    <p className="text-black font-bold text-sm">
      Mobile Number: {state.mobilenumber}
    </p>
    <p className="text-black font-bold text-sm">
      Address: {state.address}
    </p>
    <p className="text-black font-bold text-sm">
      District: {state.district}
    </p>
    <p className="text-black font-bold text-sm">
      Area: {state.area}
    </p>
    <p className="text-black font-bold text-sm">
      City: {state.city}
    </p>
    <p className="text-black font-bold text-sm">
      State: {state.utstate}
    </p>
    <p className="text-black font-bold text-sm">Pin: {state.pin}</p>
    <p className="text-black font-bold text-sm">
      Landmark: {state.landmark}
    </p>                      
  </div>
)}

                </div>
                {products.map((p, i) => (
                  <div key={i} className="flex bg-white p-4 flex-col gap-2">
                    <div className="flex justify-start items-center">
                      <h2 className="text-md text-slate-600">{p.shopName}</h2>
                    </div>
                    {p.products.map((pt, j) => (
                      <div key={i + 99} className="w-full flex flex-wrap">
                        <div className="flex sm:w-full gap-2 w-7/12">
                          <div className="flex gap-2 justify-start items-center">
                            <img
                              className="w-[80px] h-[80px]"
                              src={pt.productInfo.images.split(",")[0]}
                              alt="product image"
                            />
                            <div className="pr-4 text-slate-600">
                              <h2 className="text-md">{pt.productInfo.productName}</h2>
                              <span className="text-sm">
                                Brand : {pt.productInfo.brand}
                              </span>
                              <span className="text-sm font-bold text-black">
                                {"  "}Quantity : {pt.productInfo.quantity}
                              </span>
                            </div>                            
                          </div>
                        </div>
                        <div className="flex justify-end w-5/12 sm:w-full sm:mt-3">
                          <div className="pl-4 sm:pl-0">
                            <h2 className="text-lg text-black">
                              ₹
                              {pt.productInfo.sellingPrice -
                                Math.floor(
                                  (pt.productInfo.sellingPrice *
                                    pt.productInfo.discount) /
                                    100
                                )}
                            </h2>
                            <p className="line-through text-red-600">
                              ₹{pt.productInfo.sellingPrice}
                            </p>
                            <p>-{pt.productInfo.discount}%</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[33%] md-lg:w-full">
              <div className="pl-3 md-lg:pl-0">
                <div className="bg-white font-medium p-5 text-slate-600 flex flex-col gap-3">
                  <h2 className="text-xl font-semibold">Order Summary</h2>
                  <div className="flex justify-between items-center">
                    <span>Items Total({items})</span>
                    <span>₹{price}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Delivery Fee</span>
                    <span>₹{shipping_fee}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Total Payment</span>
                    <span>₹{price + shipping_fee}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Total</span>
                    <span>₹{price + shipping_fee}</span>
                  </div>
                  <button
                    onClick={placeOrder}
                    disabled={res ? false : true}
                    className={`px-5 py-[6px] rounded-full hover:shadow-orange-500/20 hover:shadow-lg ${
                      res ? "bg-orange-500" : "bg-orange-300"
                    } text-sm text-white uppercase font-bold`}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Shipping;
