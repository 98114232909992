import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown, MdPhone } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUser, FaLock, FaWhatsapp, FaList } from "react-icons/fa";
import {
  AiFillHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import logo from "./../assets/logo.png";

const Headers = () => {
  const user = false;
  const [showSidebar, setShowSidebar] = useState(true);
  const { pathname } = useLocation();
  const [categoryShow, setCategoryShow] = useState(true);
  const [category, setCategory] = useState("");
  const { categories } = useSelector((state) => state.home);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  // const [userLocation, setUserLocation] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const search = () => {
    navigate(`/products/search?category=${category}&&value=${searchValue}`);
  };

  const { cart_product_count, wishlist_count } = useSelector(
    (state) => state.cart
  );
  const redirect_cart_page = () => {
    if (userInfo) {
      navigate(`/cart`);
    } else {
      navigate(`/login`);
    }
  };
  // useEffect(() => {
  //   if (navigator.permissions) {
  //     navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //       if (result.state === "granted") {
  //         // Permission already granted; fetch current position
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const latitude = position.coords.latitude;
  //             const longitude = position.coords.longitude;

  //             setUserLocation({ latitude, longitude });
  //           },
  //           (error) => {
  //             console.error(`Error getting location: ${error.message}`);
  //           }
  //         );
  //       } else if (result.state === "prompt") {
  //         // Request permission
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const latitude = position.coords.latitude;
  //             const longitude = position.coords.longitude;

  //             setUserLocation({ latitude, longitude });
  //           },
  //           (error) => {
  //             console.error(`Error getting location: ${error.message}`);
  //           }
  //         );
  //       } else {
  //         // Permission denied
  //         console.error("Geolocation permission denied by user.");
  //       }
  //     });
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  return (
    <>
      <div className="w-full bg-[#ffffff]">
        <div className="header-top bg-[#3EB489] ">
          <div className="w-[85%] lg:w-[90%] mx-auto">
            <div
              className="flex w-full justify-between items-center h-[50px] text-black-500 cursor-pointer lg:flex md-lg:flex xl:flex"
              onClick={() => setShowSidebar(false)}
            >
              <span className="text-xl">
                <FaList />
              </span>
              <Link to="/">
                <img
                  src={logo}
                  alt="Company Logo"
                  style={{ width: "224px", height: "70px" }}
                />
              </Link>
              {/* {userLocation && (
                <span>
                  Your Location: {userLocation.latitude},{" "}
                  {userLocation.longitude}
                </span>
              )} */}
              <ul className="flex justify-start items-center gap-8 md-lg:hidden">
                <li className="flex relative justify-center items-center gap-2 text-sm after:absolute after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px]"></li>
              </ul>
              <div>
                {/* <div className="flex justify-center items-center gap-10 md-lg:hidden">
                  <div className="flex justify-center items-center gap-4 "></div>
                  <div className="flex group cursor-pointer text-slate-800 text-sm justify-center items-center gap-1 relative after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px] after:absolute before:absolute before:h-[18px] before:bg-[#afafaf] before:w-[1px] before:-left-[20px]">
                    <img
                      src="http://localhost:3000/language.png"
                      alt=""
                      style={{
                        width: "25px",
                        height: "20px",
                        display: "inline-block",
                      }}
                    />
                    <span>
                      <MdOutlineKeyboardArrowDown />
                    </span>
                    <ul className="absolute invisible transition-all to-12 rounded-sm duration-200 text-white p-2 w-[100px] flex flex-col gap-3 group-hover:visible group-hover:top-6 group-hover:bg-black z-10">
                      <li>English</li>
                      <li>Hindi</li>
                      <li>Bengali</li>
                      <li>Tamil</li>
                      <li>Telegu</li>
                      <li>Malayalam</li>
                      <li>Kannada</li>
                      <li>Marathi</li>
                      <li>Gujrathi</li>
                      <li>Punjabi</li>
                      <li>Manipuri</li>
                    </ul>
                  </div>
                </div> */}
                {userInfo ? (
                  <div className="flex flex-col items-center">
                    <Link
                      className="flex cursor-pointer justify-center items-center gap-2 text-sm hover:text-blue-800 hover:shadow-md"
                      to="/dashboard"
                    >
                      <span>
                        <FaUser />
                      </span>
                      <span className="font-bold italic">
                        Hello {userInfo.name.split(" ")[0]}
                      </span>

                      <span>My Accounts</span>
                    </Link>
                  </div>
                ) : (
                  <Link
                    to="/login"
                    className="flex cursor-pointer justify-center items-center gap-2 text-sm hover:text-blue-800 hover:shadow-md"
                  >
                    <span>
                      <FaLock />
                    </span>
                    <span>Login</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-white">
        <div className="w-[85%] lg:w-[90%] mx-auto">
          <div className="h-[80px] md-lg:h-[00px] flex justify-between items-center flex-wrap">
            <div className="md-lg:w-full w-3/12 md-lg:pt-4">
              <div className="flex justify-between items-center"></div>
            </div>
            <div className="md-lg:w-full w-9/12">
              <div className="flex justify-between md-lg:justify-center items-center flex-wrap pl-8">
                <ul className="flex justify-start items-start gap-8 text-sm font-bold uppercase md-lg:hidden">
                  <li>
                    <Link
                      to="/"
                      className={`p-2 block ${
                        pathname === "/" ? "text-[#7fad39]" : "text-slate-600"
                      }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/shops"
                      className={`p-2 block ${
                        pathname === "/shops"
                          ? "text-[#7fad39]"
                          : "text-slate-600"
                      }`}
                    >
                      Products
                    </Link>
                  </li>
                </ul>
                <div className="flex md-lg:hidden justify-center items-center gap-5">
                  <div className="flex justify-center gap-5">
                    <div
                      onClick={() =>
                        navigate(userInfo ? "/dashboard/my-wishlist" : "/login")
                      }
                      className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
                    >
                      <span className="text-xl text-red-500">
                        <AiFillHeart />
                      </span>
                      {wishlist_count !== 0 && (
                        <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                          {wishlist_count}
                        </div>
                      )}
                    </div>
                    <div
                      onClick={redirect_cart_page}
                      className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
                    >
                      <span className="text-xl text-blue-700">
                        <AiOutlineShoppingCart />
                      </span>
                      {cart_product_count !== 0 && (
                        <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                          {cart_product_count}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md-lg:block">
        <div
          onClick={() => setShowSidebar(true)}
          className={`fixed duration-200 transition-all ${
            showSidebar ? "invisible" : "visible"
          } hidden md-lg:block w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0 z-20`}
        ></div>
        <div
          className={`w-[300px] z-[9999] transition-all duration-200 fixed  ${
            showSidebar ? "-left-[300px]" : "left-0"
          } top-0 overflow-y-auto bg-white h-screen py-6 px-8`}
        >
          <div className="flex justify-start flex-col gap-6">
            <Link to="/">
              <img
                src={logo}
                alt="Company Logo"
                style={{ width: "224px", height: "70px" }}
              />
            </Link>
            <div className="flex justify-start items-center gap-10">
              {userInfo ? (
                <Link
                  className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                  to="/dashboard"
                >
                  <span>
                    <FaUser />
                  </span>
                  <span className="font-bold italic">
                    Hello {userInfo.name.split(" ")[0]}
                  </span>
                  <p>My Accounts</p>
                </Link>
              ) : (
                <div className="flex cursor-pointer justify-center items-center gap-2 text-sm">
                  <span>
                    <FaLock />
                  </span>
                  <span>Login</span>
                </div>
              )}
            </div>
            <ul className="flex flex-col justify-start items-start  text-md font-semibold uppercase">
              <li>
                <Link
                  className={`py-2 block ${
                    pathname === "/" ? "text-[#7fad39]" : "text-slate-600"
                  }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/shops"
                  className={`py-2 block ${
                    pathname === "/shops" ? "text-[#7fad39]" : "text-slate-600"
                  }`}
                >
                  Products
                </Link>
              </li>              
            </ul>
          </div>
          <div className="w-full flex justify-end md-lg:justify-start gap-3 items-center">
            <a
              href="tel:+919732695909"
              style={{ textDecoration: "none", color: "#333" }}
              title="Call us at +919732695909"
            >
              <div className="w-[48px] h-[48px] rounded-full flex bg-[#f5f5f5] justify-center items-center">
                <MdPhone size={24} color="green" />
              </div>
            </a>
            <div className="flex justify-end flex-col gap-1">
              <a
                href="tel:+919732695909"
                style={{ textDecoration: "none", color: "#333" }}
                title="Call us at +919732695909"
              >
                <h2 className="text-sm font-medium text-slate-700">
                  +919732695909
                </h2>
                <span className="text-xs">24/7 Support</span>
              </a>
            </div>
          </div>
          <div className="w-full flex justify-end md-lg:justify-start gap-3 items-center">
            <a
              href="https://wa.me/919732695909"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#333" }}
              title="whatsapp us at +919732695909"
            >
              <div className="w-[48px] h-[48px] rounded-full flex bg-[#f5f5f5] justify-center items-center">
                <FaWhatsapp size={24} color="#25d366" />
              </div>
            </a>
            <div className="flex justify-end flex-col gap-1">
              <a
                href="https://wa.me/919732695909"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#333" }}
                title="whatsapp us at +919732695909"
              >
                <h2 className="text-sm font-medium text-slate-700">
                  9732695909
                </h2>
                <span className="text-xs">WhatsApp Support</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[85%] lg:w-[90%] mx-auto">
        <div className="flex w-full flex-wrap md-lg:gap-8">
          <div className="w-2/12 md-lg:w-full">
            <div className="bg-white relative">
              <div
                onClick={() => setCategoryShow(!categoryShow)}
                className="h-[50px] bg-orange-500 text-white flex justify-center md-lg:justify-between md-lg:px-6 items-center gap-3 font-bold text-md cursor-pointer md-lg:hidden"
              >
                <div className="flex justify-center items-center gap-3 ">
                  <span className="text-xl">
                    <FaList />
                  </span>
                  <span>Category</span>
                </div>
                <span className="pt-1">
                  <MdOutlineKeyboardArrowDown />
                </span>
              </div>
              <div
                className={`${
                  categoryShow ? "h-0" : "h-[400px]"
                } overflow-hidden transition-all md-lg:relative duration-500 absolute z-[99999] bg-white w-full border-x`}
              >
                <ul
                  onClick={() => setCategoryShow(!categoryShow)}
                  className="py-2 text-slate-600 font-medium h-full overflow-auto"
                >
                  {categories.map((c, i) => {
                    return (
                      <li
                        key={i}
                        className="flex justify-start items-center gap-2 px-[24px] py-[6px]"
                      >
                        <img
                          src={c.image}
                          className="w-[30px] h-[30px] rounded-full object-cover object-center overflow-hidden"
                          alt=""
                        />
                        <Link
                          to={`/products?category=${c.name}`}
                          className="text-sm block"
                        >
                          {c.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="w-10/12 pl-8 md-lg:pl-0 md-lg:w-full">
            <div className="flex flex-wrap w-full justify-between items-center md-lg:gap-6">
              <div className="w-10/12 md-lg:w-full">
                <div className="flex border h-[50px] items-center relative gap-5 rounded-md">
                  <div className="relative after:absolute after:h-[25px] after:w-[1px] after:bg-[#afafaf] after:-right-[15px] md:hidden">
                  <label htmlFor="categorySelect" className="sr-only">Category</label>
                    <select
                      onChange={(e) => setCategory(e.target.value)}
                      className="w-[160px] text-slate-600 font-semibold bg-transparent px-2 h-full outline-0 border-none"
                      name="Category"
                      id="categorySelect"
                    >
                      <option value="">Select Category </option>
                      {categories.map((c, i) => (
                        <option key={i} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    className="w-full relative bg-transparent text-black outline-0 px-3 h-full"
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="text"
                    name="Search Input"
                    id="searchinput"
                    placeholder="Search Gorudh.com"
                  />
                  <button
                    onClick={search}
                    className="bg-orange-500 right-0 absolute px-4 h-full font-semibold uppercase text-white rounded-md"
                    aria-label="Search Button"
                  >
                    <span className="text-2xl">
                      <AiOutlineSearch />
                    </span>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Headers;
