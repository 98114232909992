import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactGA from 'react-ga4';
import Home from "./pages/Home";
import Shops from "./pages/Shops";
import Cart from "./pages/Cart";
import Details from "./pages/Details";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Shipping from "./pages/Shipping";
import { get_category } from './store/reducers/homeReducer'
import CategoryShops from './pages/CategoryShop';
import SearchProducts from './pages/SearchProducts';
import Payment from './pages/Payment';
import Dashboard from './pages/Dashboard';
import OfferProducts from './pages/OfferProducts';
import ProtectUser from './utils/ProtectUser';
import Index from './components/dashboard/Index';
import Orders from './components/dashboard/Orders';
import Wishlist from './components/dashboard/Wishlist';
import ChangePassword from './components/dashboard/ChangePassword';
import Order from './components/dashboard/Order';
import ReturnOrder from './components/dashboard/ReturnOrder';
import CatalogueIndex from "./pages/CatalogueIndex";
import CataloguePage from "./pages/CataloguePage";
import AboutUs from "./pages/About_Us";
import DeliveryInformation from "./pages/Delivery_Information";
import PrivacyPolicy from "./pages/Privacy_Policy";
// import Chat from './components/dashboard/Chat';
// import ChatPopup from './components/ChatPopup';
// import ConfirmOrder from './pages/ConfirmOrder';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_category());
  }, []);
  return (
    <BrowserRouter>
    <GA4RouteTracker /> {/* Add the tracker component */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shops" element={<Shops />} />
        <Route path='/products?' element={<CategoryShops />} />
        <Route path="/products/search?" element={<SearchProducts />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/product/details/:slug" element={<Details />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/product/offer/:idbanners" element={<OfferProducts />} />
        <Route path="/catalogueIndex/:brand/:model/:year/:variant/:subVariant" element={<CatalogueIndex />} />
        <Route path="/cataloguePage/:brand/:model/:year/:variant/:subVariant/:idcatalogue" element={<CataloguePage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/deliveryinformation" element={<DeliveryInformation />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />

        <Route path='/dashboard' element={<ProtectUser />}>
          <Route path='' element={<Dashboard />}>
            <Route path='' element={<Index />} />
            <Route path='my-orders' element={<Orders />} />
            <Route path='my-wishlist' element={<Wishlist />} />
            <Route path='order/details/:orderId' element={<Order />} />
            <Route path='chage-password' element={<ChangePassword />} />
            {/* <Route path='chat' element={<Chat />} />
            <Route path='chat/:sellerId' element={<Chat />} /> */}
            <Route path='order/return/:orderId' element={<ReturnOrder />} />
          </Route>
        </Route>


        {/*        
        <Route path="/order/confirm?" element={<ConfirmOrder />} /> 
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

const GA4RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

export default App;
