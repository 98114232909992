import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { trackEvent } from "../utils/ga"; // Import the trackEvent function
import { getCatalogue } from "../store/reducers/catalogueReducer";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import {
  add_to_cart,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cartReducer";

const Catalogue = () => {
  const { brand, model, year, variant, subVariant, idcatalogue } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getCatalogue({ brand, model, year, variant, subVariant, idcatalogue })
    );
    trackEvent('Catalogue', 'Page View', `${brand} ${model} ${year} ${variant} ${subVariant}`);
  }, [dispatch]);
  const { catalogue, parts, successMessage, errorMessage } = useSelector(
    (state) => state.catalogue
  );
  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage: cartSuccessMessage, errorMessage: cartErrorMessage } =
    useSelector((state) => state.cart);

  const [showModal, setShowModal] = useState(false);
  const [hoveredSellerData, setHoveredSellerData] = useState(null); // State to store hovered sellerData
  // const [clicked, setClicked] = useState(false);
  const [clickedRows, setClickedRows] = useState(
    Array(parts.length).fill(false)
  );

  const image = catalogue.image;

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    if (cartErrorMessage) {
      toast.error(cartErrorMessage);
      dispatch(messageClear());
    }
    if (cartSuccessMessage) {
      toast.success(cartSuccessMessage);
    }
  }, [cartSuccessMessage, cartErrorMessage, dispatch]);

  const scrollToRow = (index) => {
    const row = document.getElementById(`row-${index}`);
    if (row) {
      // Highlight the row temporarily
      row.classList.add("bg-green-200"); // Adjust this class according to your styling
      setTimeout(() => {
        row.classList.remove("bg-green-200");
      }, 2000); // Adjust the duration as needed
      // Highlight the entire section
      const section = document.getElementById(`section-${index}`);
      if (section) {
        section.classList.add("bg-yellow-200"); // Adjust this class according to your styling
        setTimeout(() => {
          section.classList.remove("bg-yellow-200");
        }, 2000); // Adjust the duration as needed
      }

      row.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleReferencePointHover = (data) => {
    setHoveredSellerData(data); // Store hovered sellerData in state
    setShowModal(true);
  };

  const closeModal = () => {
    setHoveredSellerData(null);
    setShowModal(false);
  };

  const add_cart = (inventoryId) => {
    //remove userInfo to enable add to cart before login
    if (userInfo) {
      dispatch(
        add_to_cart({
          userId: userInfo.id,
          quantity: 1,
          inventoryId: inventoryId,
        })
      );
      trackEvent('Cart', 'Add to Cart', `Inventory ID: ${inventoryId}`);
    } else {
      navigate("/login");
    }
  };

  const add_wishlist = (pro, index) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        inventoryId: pro.inventoryId,
        name: pro.productName,
        price: pro.sellingPrice,
        image: pro.images,
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
    const updatedClickedRows = [...clickedRows];
    updatedClickedRows[index] = true;
    setClickedRows(updatedClickedRows);
    trackEvent('Wishlist', 'Add to Wishlist', `Product Name: ${pro.productName}`);
  };

  return (
    <div>
      <Headers />
      <div className="px-2 lg:px-7 pt-5 mt-2 bg-gray-200">
        <h1 className="text-2xl font-semibold mb-4">Catalogue</h1>
        <div className="flex flex-wrap">
          <div>
            <div className="bg-gray-200 p-4 rounded-md">
              <h2 className="text-lg font-semibold mb-4">Catalogue Image</h2>
              {image && (
                <div className="mt-4 relative ">
                  <img src={image} alt="image" className="max-w-full h-auto" />

                  {/* Display clickable reference points */}
                  {parts.map((part, index) => (
                    <div
                      key={index}
                      className="absolute"
                      style={{
                        top: `${part.cataloguePart.y * 100}%`,
                        left: `${part.cataloguePart.x * 100}%`,
                        transform: "translate(-50%, -50%)", // Center the reference point
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onMouseEnter={() =>
                        handleReferencePointHover(part.partsData)
                      }
                      onMouseLeave={closeModal}
                      onClick={() => scrollToRow(index)}
                    >
                      <div className="w-10 h-10 bg-transparent rounded-full flex items-center justify-center">
                        <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center text-white">
                          {index + 1}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div className="bg-gray-200 p-4 rounded-md">
              <h2 className="text-lg font-semibold mb-4">Part Details</h2>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-1 py-2">Sl No</th>
                    <th className="px-1 py-2">Part No</th>
                    <th className="px-1 py-2">Title</th>
                    <th className="px-1 py-2">Description</th>
                    <th className="px-1 py-2">Qty</th>
                    <th className="px-1 py-2">Superseded</th>
                    <th className="px-1 py-2">Alternate Part No</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map over parts and display them in table rows */}
                  {parts.map((part, index) => (
                    <React.Fragment key={index}>
                      {/* Render top border for each row except the first one */}
                      {index !== 0 && (
                        <tr className="border-t border-slate-400"></tr>
                      )}
                      <tr id={`row-${index}`}>
                        <td className="px-1 py-2">{index + 1}</td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.partNo}
                        </td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.title}
                        </td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.description}
                        </td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.quantity}
                        </td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.superseded}
                        </td>
                        <td className="px-1 py-2">
                          {part.cataloguePart.alternatePartNo}
                        </td>
                      </tr>
                      <tr key={`${index}`}>
                        <td colSpan="7" className="px-1 py-2">
                          <div id={`section-${index}`}>
                            <table className="w-full">
                              <tbody>
                                {part.partsData.map(
                                  (sellerData, sellerIndex) => (
                                    <tr
                                      key={`${index}-${sellerIndex}`}
                                      className="border-t border-slate-400"
                                    >
                                      <td>
                                        <img
                                          className="w-[45px] h-[45px]"
                                          src={sellerData.images.split(",")[0]}
                                          alt=""
                                        />
                                      </td>
                                      <td className="text-blue-600 font-bold">
                                        <Link
                                          to={`/product/details/${sellerData.slug}`}
                                          className="hover:text-blue-800 hover:underline focus:outline-none focus:shadow-outline"
                                        >
                                          {sellerData.productName}
                                        </Link>
                                      </td>
                                      <td className="text-gray-800 font-semibold">{`Brand: ${sellerData.brand}`}</td>
                                      <td className="text-black font-bold">{`Price: ${sellerData.sellingPrice}`}</td>
                                      <td className="text-gray-800 font-semibold">{`Seller: ${sellerData.shopName}`}</td>
                                      <td className="flex items-center">
                                        <span
                                          onClick={() =>
                                            add_wishlist(sellerData, index)
                                          }
                                          className={`h-[38px] w-[38px] flex justify-center items-center cursor-pointer hover:shadow-lg shadow-cyan-500/40 bg-slate-300 text-red-${
                                            clickedRows[index] ? "500" : "100"
                                          } rounded-full`}
                                        >
                                          <AiFillHeart />
                                        </span>

                                        <span
                                          onClick={() =>
                                            add_cart(sellerData.inventoryId)
                                          }
                                          className="mt-2 ml-2 w-[38px] h-[38px] cursor-pointer bg-white flex justify-center text-blue-700 items-center rounded-full hover:bg-[#ffee53] hover:text-black hover:rotate-[720deg] transition-all"
                                        >
                                          <AiOutlineShoppingCart />
                                        </span>
                                        {/* <button onClick={() => add_cart(sellerData.idproducts)} className="mt-2 bg-blue-500 text-white px-1 py-1 ml-2 rounded-md">Add to Cart <AiOutlineShoppingCart /> </button> */}
                                        {/* <button className="mt-2 bg-orange-500 text-white px-1 py-1 ml-2 rounded-md">Buy now</button> */}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-8">
          {/* Add margin between the table and footer */}
        </div>
      </div>
      <Footer />
      {/* Modal for displaying part details */}
      {showModal && hoveredSellerData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            {hoveredSellerData.map((sellerData, sellerIndex) => (
              <div key={sellerIndex} className="mb-4 flex flex-col items-center">
              <img
                src={sellerData.images.split(",")[0]}
                alt="Part Image"
                className="max-w-[200px] h-auto mb-2"
              />
              <h2 className="text-lg font-semibold mb-2 text-center">
                <Link
                  to={`/product/details/${sellerData.slug}`}
                  className="hover:text-blue-800 hover:underline focus:outline-none focus:shadow-outline"
                >
                  {sellerData.productName}
                </Link>
              </h2>
              <p className="text-gray-800 font-semibold">{`Brand: ${sellerData.brand}`}</p>
              <p className="text-black font-bold">{`Price: ${sellerData.sellingPrice}`}</p>
            </div>
            
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Catalogue;

