import React, { useState, useEffect, useRef } from "react";

const Msg91CustomOTPWidget = ({
  mobileNumber,
  otpDigits,
  handleOtpDigitChange,
  handleResendOtp,
  otpTimer,
  isResendDisabled,
  handleOtpSubmit,
  setShowOtpModal,
}) => {
  useEffect(() => {
    // Configuration for MSG91 OTP widget
    const formattedMobileNumber = "91" + mobileNumber; // Prepend "91" to the mobile number

    // console.log("Formatted Mobile Number:", formattedMobileNumber); // Log the formatted mobile number

    var configuration = {
      widgetId: "346468734c76313535363934",
      tokenAuth: "419600TfukJGgl6612cd7dP1",
      identifier: formattedMobileNumber, // Use the provided mobile number as identifier
      exposeMethods: true, // Expose methods for OTP functionality
       success: (data) => {
        // console.log("Return Response", data);
        handleOtpSubmit(data, mobileNumber);

        // Handle success response
      },
      failure: (error) => {
        // console.log("failure reason", error);
        // Handle failure response
      },
    // recaptchaCallback: (token) => {
    //   console.log("reCAPTCHA token:", token);
    //   // Store or use the reCAPTCHA token as needed
    // }
};

    // Load MSG91 OTP widget script
    const script = document.createElement("script");
    script.onload = () => {
      // Initialize OTP widget once script is loaded
      window.initSendOTP(configuration);
    };
    script.src =
      "https://control.msg91.com/app/assets/otp-provider/otp-provider.js";
    document.body.appendChild(script);

    // Cleanup function to remove script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [mobileNumber]); // Trigger effect when mobileNumber changes

  // Function to handle sending OTP
  //   const sendOTP = () => {
  //     const formattedMobileNumber = "91" + mobileNumber; // Prepend "91" to the mobile number
  //     console.log("formattedMobileNumber:",formattedMobileNumber);
  //     window.sendOtp(
  //       formattedMobileNumber, // Identifier: mobile number
  //       (data) => console.log("OTP sent successfully."),
  //       (error) => console.log("Error occurred")
  //     );
  // };

  // Function to handle retrying OTP
  const retryOTP = () => {
    window.retryOtp(
      "11", // Default configuration
      (data) => console.log("Resend data:", data),
      (error) => console.log("Error occurred")
    );
    handleResendOtp();
    setIsButtonDisabled(true); // Disable the button again
  };

  // Function to handle verifying OTP
  const verifyOTP = (otp) => {
    window.verifyOtp(
      otp, // OTP entered by the user
      (data) => console.log("OTP verified:", data),
      (error) => console.log("Error occurred")
    );
  };

  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpDigits.length);
  }, [otpDigits]);

  const focusNextInput = (index) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    // Reset the timer when showOtpModal changes
    if (!isButtonDisabled && otpTimer === 60) {
      const timer = setTimeout(() => {
        setIsButtonDisabled(true);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [otpTimer, isButtonDisabled]);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Enter OTP
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-700 font-semibold">
                    OTP has been sent to your Mobile No ending with{" "}
                    {mobileNumber.slice(-4)}.
                  </p>
                  <div className="flex justify-center space-x-4 mt-4">
                    {otpDigits &&
                      otpDigits.length === 6 &&
                      otpDigits.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => {
                            handleOtpDigitChange(index, e.target.value);
                            focusNextInput(index);
                          }}
                          ref={(input) => (inputRefs.current[index] = input)}
                          className="w-12 h-12 border border-slate-200 outline-none text-center focus:border-indigo-500 rounded-md"
                        />
                      ))}
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      onClick={retryOTP}
                      disabled={isButtonDisabled || isResendDisabled}
                      className={`text-sm ${
                        isButtonDisabled ? "text-gray-500" : "text-blue-500 font-semibold"
                      }`}
                    >
                      Resend OTP
                    </button>
                    {isButtonDisabled && otpTimer > 0 && (
                      <>
                        <span className="ml-2 text-sm text-gray-500">
                          {otpTimer} Sec
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => verifyOTP(otpDigits.join(""))}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Submit
            </button>
            <button
              onClick={() => setShowOtpModal(false)}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Msg91CustomOTPWidget;


