import React from 'react';
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-gray-100  md: lg: xl:">
      <Headers />
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>
      <div className="text-lg">
        <p className="mb-4">
          At GORUDH, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website or services.
        </p>
        <h2 className="text-xl font-semibold mb-2">Information We Collect</h2>
        <p className="mb-4">
          We may collect personal information such as your name, email address, phone number, shipping address, and payment details when you place an order or register an account on our website. We may also collect non-personal information such as your IP address, browser type, and device information for analytical purposes.
        </p>
        <h2 className="text-xl font-semibold mb-2">How We Use Your Information</h2>
        <p className="mb-4">
          We use the information we collect to process your orders, communicate with you about your purchases, provide customer support, and improve our products and services. We may also use your information to send you promotional offers and updates about our company, but you can opt out of these communications at any time.
        </p>
        <h2 className="text-xl font-semibold mb-2">Data Security</h2>
        <p className="mb-4">
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use industry-standard encryption technology to secure sensitive data transmitted over the internet.
        </p>
        <h2 className="text-xl font-semibold mb-2">Third-Party Disclosure</h2>
        <p className="mb-4">
          We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill orders and provide services. We may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you.
        </p>
        <p className="mb-4">
          This Privacy Policy may be updated periodically to reflect changes in our practices or legal requirements. We encourage you to review this page regularly for the latest information on our privacy practices.
        </p>
        <p>
          If you have any questions or concerns about our Privacy Policy, please contact us at support@gorudh.com.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
