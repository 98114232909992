import React from 'react';
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const DeliveryReturnPolicyPage = () => {
  return (
    <div className="bg-gray-100  md: lg: xl:">
        <Headers />
      <h1 className="text-3xl font-bold text-center mb-6">Delivery and Return Policy</h1>
      <div className="text-lg">
        <h2 className="text-xl font-semibold mb-2">Delivery Policy</h2>
        <p className="mb-4">
          We strive to deliver your orders in a timely and efficient manner. Our delivery times may vary depending on your location and the availability of the products you've ordered. You can track the status of your order through your account dashboard or by contacting our customer support team.
        </p>
        <p className="mb-4">
          We offer standard and expedited shipping options for domestic and international orders. Shipping costs and delivery estimates will be calculated at checkout based on the shipping method and destination.
        </p>
        <h2 className="text-xl font-semibold mb-2">Return Policy</h2>
        <p className="mb-4">
          We want you to be completely satisfied with your purchase. If you're not happy with your order for any reason, you may return (for refundable items only) it within 7 days of receiving it for a full refund or exchange. To initiate a return, please contact our customer support team and provide your order details.
        </p>
        <p className="mb-4">
          Returned items must be unused, in their original packaging, and accompanied by proof of purchase. Once we receive your returned items, we will process your refund or exchange within 2-3 days. Please note that shipping costs are non-refundable, and you will be responsible for the return shipping fees unless the return is due to our error or a defective product.
        </p>
        <p>
          If you have any questions or concerns about our Delivery and Return Policy, please contact us at support@example.com.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryReturnPolicyPage;
