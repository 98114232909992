import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_order } from "../../store/reducers/orderReducer";

const Order = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { myOrder } = useSelector((state) => state.order);
  const { userInfo } = useSelector((state) => state.auth);
  // Assuming myOrder.createdAt is "2024-02-11T20:24:41.000Z"
  const formattedDate =
    myOrder && myOrder.createdAt ? new Date(myOrder.createdAt) : null;
  // Check if formattedDate is not null and is a valid Date object
  const displayDate =
    formattedDate instanceof Date && !isNaN(formattedDate)
      ? `${formattedDate.getDate()}-${formattedDate.toLocaleString("default", {
          month: "short",
        })}-${formattedDate.getFullYear()}`
      : "";
  // Get the time portion
  const displayTime =
    formattedDate instanceof Date && !isNaN(formattedDate)
      ? formattedDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "";
  useEffect(() => {
    dispatch(get_order(orderId));
  }, [dispatch, orderId]);

  const shippingInfo =
    myOrder && myOrder.shippingInfo ? JSON.parse(myOrder.shippingInfo) : null;

  // const productsArray = JSON.parse(myOrder.products);

  // const products = myOrder && myOrder.products ?JSON.parse(myOrder) : null;
 

  return (
    <div className="bg-white p-5 rounded-md">
      <h2 className="text-black bg-slate-300 font-semibold">
        # Order Id {myOrder?.idorders} ,{" "}
        <span className="pl-1">
          {displayDate} {displayTime}
        </span>
      </h2>
      <div className="grid grid-cols-2 gap-3">
        <div className="bg-yellow-200 p-4 rounded-lg">
          <div className="flex flex-col gap-1">
            <h2 className="text-black font-semibold">
              Deliver to: {shippingInfo?.name}
            </h2>
            <p className="text-slate-600 text-sm">
              {shippingInfo?.address} <br />
              {shippingInfo?.district} <br />
              {shippingInfo?.city} <br />
              {shippingInfo?.area} <br />
              {shippingInfo?.utstate} <br />
              {shippingInfo?.pin} <br />
              {shippingInfo?.landmark}
            </p>
          </div>
        </div>

        <div className="text-slate-600">
          <h2>Price: ₹{myOrder?.price} Shipping Fee Include </h2>
          <p>
            Payment status:{" "}
            <span
              className={`py-[1px] text-xs px-3 ${
                myOrder?.payment_status === "Paid"
                  ? "bg-green-300 text-black"
                  : "bg-red-300 text-black"
              } rounded-md `}
            >
              {myOrder?.payment_status}
            </span>
          </p>
          <p>
            Order status:{" "}
            <span
              className={`py-[1px] text-xs px-3 ${
                myOrder?.delivery_status === "Processing"
                  ? "bg-green-300 text-black"
                  : "bg-red-300 text-black"
              } rounded-md `}
            >
              {myOrder?.delivery_status}
            </span>
          </p>
        </div>
      </div>
      <div className="mt-3">
        <h2 className="text-slate-600 text-lg pb-2">Products</h2>
        <div className="flex gap-5 flex-col">
          {myOrder.products?.map((p, i) => (
           <div key={i} className="flex justify-start items-center text-black border-b">
           <div className="flex gap-2 items-center" style={{ width: '1200px' }}> {/* Added inline style for width */}
             <img
               className="w-[80px] h-[80px]"
               src={p.images.split(",")[0]}
               alt="image"
             />
             <div className="flex flex-col justify-start"> {/* Removed items-start */}
               <Link className="hover:text-blue-500 hover:shadow-md">{p.productName}</Link>
               <p><span>Brand: {p.brand}</span></p>
               <p><span>Quantity: {p.quantity}</span></p>
             </div>
           </div>
           <div className="pl-4 flex flex-col justify-between" style={{ width: '200px' }}> {/* Added inline style for width */}
             {p.discount === 0 ? (
               <h2 className="text-md text-orange-500">₹{p.sellingPrice}</h2>
             ) : (
               <div>
                 <h2 className="text-md text-orange-500">
                   ₹{p.sellingPrice - Math.floor((p.sellingPrice * p.discount) / 100)}
                 </h2>
                 <p>₹{p.mrp}</p>
                 <p>-{p.discount}%</p>
               </div>
             )}
           </div>
         </div>
         
         
          ))}
        </div>
      </div>
    </div>
  );
};

export default Order;
