import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Ratings from "../components/Ratings";
import { AiFillHeart} from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import toast from "react-hot-toast";
import { trackPageView, trackEvent } from "../utils/ga";
import Reviews from "../components/Reviews";
import Questions from "../components/Questions";
import { get_product } from "../store/reducers/homeReducer";
import {
  add_to_cart,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cartReducer";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

const Details = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    product,
    relatedProducts,
    moreProducts,
    totalReview,
    // totalQuestions,
    buyBox,
  } = useSelector((state) => state.home);
  const { userInfo } = useSelector((state) => state.auth);
  const { errorMessage, successMessage } = useSelector((state) => state.cart);
  const [mainImages, setMainImage] = useState("");
  const [state, setState] = useState("description");
  const [quantity, setQuantity] = useState(1);
  const [clicked, setClicked] = useState(false);
  // const [askQuestion, setAskQuestion] = useState(false); // State to toggle the input box for asking questions
  // const [questionText, setQuestionText] = useState(""); // State to store the question text
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 3,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(get_product(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    if (product.productName) {
      trackPageView(`/product/details/${product.slug}`); // Track the page view
    }
  }, [product]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [dispatch, errorMessage, successMessage]);

  useEffect(() => {
    if (product.images && product.images.length > 0) {
      setMainImage(product.images.split(",")[0]);
    }
  }, [product]);

  const inc = () => {
    if (quantity >= product.stock) {
      toast.error("Out of stock");
    } else {
      setQuantity(quantity + 1);      
    }
  };

  const dec = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const add_cart = (inventoryId) => {
    if (userInfo) {
      dispatch(
        add_to_cart({
          userId: userInfo.id,
          quantity,
          inventoryId: inventoryId,
        })
      );
      trackEvent("Cart", "Add to Cart", product.productName);
    } else {
      navigate("/login");
    }
  };
  const add_wishlist = () => {
    if (userInfo) {
      dispatch(
        add_to_wishlist({
          userId: userInfo.id,
          inventoryId: product.inventoryId,
          name: product.productName,
          price: product.sellingPrice,
          image: product.images,
          discount: product.discount,
          rating: product.rating,
          slug: product.slug,
        })
      );
      setClicked(true);
      trackEvent("Wishlist", "Add to Wishlist", product.productName);
    } else {
      navigate("/login");
    }
  };

  

  const buy = () => {
    let price = 0;
    if (product.discount !== 0) {
      price =
        product.sellingPrice -
        Math.floor((product.sellingPrice * product.discount) / 100);
    } else {
      price = product.sellingPrice;
    }
    const obj = [
      {
        idsellers: product.idsellers,
        shopName: product.shopName,        
        price,
        products: [
          {            
            idsellers: product.idsellers,
            quantity,
            productInfo: product,
          },
        ],
      },
    ];
    navigate("/shipping", {
      state: {
        products: obj,
        price: price * quantity,
        shipping_fee: 85,
        items: 1,
      },
    });
    trackEvent("Purchase", "Buy Now", product.productName);
  };
  const discount = parseFloat(product.discount);
  const sendWhatsAppMessage = () => {
    const productName = encodeURIComponent(product.productName);
    const productImage = encodeURIComponent(product.images.split(",")[0]);
    const productDescription = encodeURIComponent(product.description);
    const currentPageUrl = encodeURIComponent(window.location.href);
    const message = `Check out this product: ${productName}%0A%0A${productDescription}%0A%0A${productImage}%0A%0A${currentPageUrl}`;
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };
  
  const handleImageClick = (img) => {
    setMainImage(img);
    trackEvent("Image", "Click", img); // Track image click event
  };

  const canonicalUrl = `https://www.gorudh.com/product/details/${product.slug}`;
  return (
    <div>
       <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Headers />
      <div className="bg-whitw h-[5px] mt-6 bg-cover bg-no-repeat relative bg-left">
        <div className="absolute left-0 top-0 w-full h-full ]">
          <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex flex-col justify-center gap-1 items-center h-full w-full text-white">
              {/* <h2 className="text-3xl text-orange-500 font-bold">Jacked.com</h2> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-slate-100 py-2 mb-5">
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
          <div className="flex justify-start items-center text-md text-slate-700 w-full">
            <Link to="/">Home</Link>
            <span className="pt-1">
              <MdOutlineKeyboardArrowRight />
            </span>
            <Link to={`/products?category=${product.category}`}>
              {product.category}
            </Link>
            <span className="pt-1">
              <MdOutlineKeyboardArrowRight />
            </span>
            <span>{product.productName}</span>
          </div>
        </div>
      </div>
      <section>
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto pb-16">
          <div className="grid grid-cols-2 md-lg:grid-cols-1 gap-8">
            <div>
              <div className="p-5 border">
                <img
                  className="h-[400px] w-full object-contain object-center"
                  src={DOMPurify.sanitize(mainImages)}
                  alt="Main Image"
                />
              </div>
              <div className="py-3">
                {product.images && product.images.length > 0 && (
                  <Carousel
                    autoPlay={true}
                    infinite={true}
                    responsive={responsive}
                    transitionDuration={500}
                    containerClass="carousel-container" // Add a class to the carousel container
                    itemClass="carousel-item" // Add a class to each carousel item
                    customButton={false} // Disable the arrows
                  >
                    {product.images.split(",").map((img, i) => (
                      <div key={i} onClick={() => handleImageClick(img)}>
                        <img
                          className="h-[120px] cursor-pointer border border-gray-300 m-2 rounded-md"
                          src={DOMPurify.sanitize(img)}
                          alt={`Product Image ${i + 1}`}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-3xl text-slate-600 font-bold">
                <h2>{DOMPurify.sanitize(product.productName)}</h2>
              </div>
              <div className="flex justify-start items-center gap-4">
                <div className="flex text-xl">
                  <Ratings ratings={DOMPurify.sanitize(product.rating)}/>
                </div>
                <span className="text-green-500">({totalReview} Reviews)</span>
                <span
                  onClick={add_wishlist}
                  className={`h-[50px] w-[50px] flex justify-center items-center cursor-pointer hover:shadow-lg shadow-cyan-500/40 bg-slate-300 text-red-${
                    clicked ? "500" : "100"
                  } rounded-full`}
                >
                  <AiFillHeart />
                </span>
              </div>
              <div className="text-2xl text-black font-bold flex flex-col gap-2">
                {!isNaN(discount) && discount !== 0 ? (
                  <>
                    <div>
                      <span className="text-black">M.R.P: </span>
                      <span className="text-red-500 line-through ml-1">
                        ₹{product.mrp}
                      </span>
                    </div>
                    <div>
                      <span className="text-black">Price: </span>
                      <span>
                        ₹
                        {product.sellingPrice -
                          Math.floor(
                            (product.sellingPrice * product.discount) / 100
                          )}{" "}
                      </span>
                    </div>
                    <div>
                      <span className="text-black">Discount: </span>
                      <span>({product.discount}%)</span>
                    </div>
                  </>
                ) : (
                  <h2>Price : ₹{DOMPurify.sanitize(product.sellingPrice)}</h2>
                )}
              </div>

              <div className="text-slate-600">
                <p className="text black font-semibold">
                  Part No : {DOMPurify.sanitize(product.partNo)}
                </p>
                <p>{DOMPurify.sanitize(product.description)}</p>
              </div>
              <div className="flex gap-3 pb-10 border-b">
                {product.stock ? (
                  <>
                    <div className="flex bg-slate-200 h-[50px] justify-center items-center text-xl rounded-md">
                      <div onClick={dec} className="px-6 cursor-pointer">
                        -
                      </div>
                      <div className="px-5">{quantity}</div>
                      <div onClick={inc} className="px-6 cursor-pointer">
                        +
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={() => add_cart(product.inventoryId)}
                        className="px-2 py-1 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-emerald-500/40 bg-emerald-500 text-white rounded-md"
                      >
                        Add To Cart
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={buy}
                        className="px-8 py-1 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-orange-500/40 bg-orange-500 text-white rounded-md"
                      >
                        Buy Now
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="flex gap-5">
                <div className="w-[150px] text-black font-bold text-xl flex flex-col gap-5">
                  <span>Availability</span>
                  <span>Share on</span>
                </div>
                <div className="flex flex-col gap-5">
                  <span
                    className={`font-bold ${
                      product.stock < 3 ? "text-red-500" : "text-black"
                    }`}
                  >
                    {product.stock >= 1
                      ? product.stock < 3
                        ? `Only ${product.stock} Left In Stock`
                        : "In Stock"
                      : "Out of Stock"}
                  </span>
                  <span
                    className={`w-[38px] h-[38px] hover:bg-[#7fad39] hover:text-white flex justify-center items-center bg-green-500 rounded-full text-white font-bold`}
                    onClick={sendWhatsAppMessage}
                  >
                    <FaWhatsapp />
                  </span>
                </div>
                <div className="flex flex-col gap-5"></div>
                <div className="flex flex-col gap-5 w-[600px]">
                {buyBox && buyBox.length > 0 && (
    <div className="border border-slate-300 text-center font-semibold bg-yellow-500 rounded-md">
      From Sellers
      <div className="flex flex-col bg-white">
        {buyBox.map((product, index) => (
          <div
            className="text-left font-semibold border-slate-300 border-b px-2 py-2 flex items-center justify-between"
            key={index}
          >
           <div className="text-slate-500"> From:
    <p className="font-bold text-slate-800">
      {product.shopName} 
    </p>
  </div>
  
  <div className="text-slate-600">
  Price:
    <p className="font-bold text-slate-800">
      ₹ {product.sellingPrice}
    </p>
  </div>
            
            
            <button className="ml-4 bg-orange-500 rounded-md px-2 py-2" onClick={() => add_cart(product.inventoryId)}>
              Add To Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto pb-16">
          <div className="flex flex-wrap">
            <div className="w-[72%] md-lg:w-full">
              <div className="pr-4 md-lg:pr-0">
                <div className="grid grid-cols-2">
                  <button
                    onClick={() => setState("description")}
                    className={`py-1 px-5 hover:text-white hover:bg-slate-500 font-semibold ${
                      state === "description"
                        ? "bg-green-500 text-white"
                        : "bg-slate-200 text-slate-700"
                    } rounded-sm`}
                  >
                    Description
                  </button>
                  <button
                    onClick={() => setState("reviews")}
                    className={`py-1 hover:text-white px-5 hover:bg-slate-500 font-semibold ${
                      state === "reviews"
                        ? "bg-green-500 text-white"
                        : "bg-slate-200 text-slate-700"
                    } rounded-sm`}
                  >
                    Reviews
                  </button>
                </div>
                <div>
                  {state === "reviews" ? (
                    <Reviews product={product} />
                  ) : (
                    <>
                      <p className="py-5 text-slate-600">
                      {DOMPurify.sanitize(product.description)}
                      </p>
                      <Questions product={product} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[28%] md-lg:w-full rounded-md">
              <div className="pl-4 md-lg:pl-0">
                <div className="px-3 py-2 text-black bg-green-500 font-semibold rounded-md">
                  <h2> Other Products From {product.shopName}</h2>
                </div>
                <div className="flex flex-col gap-5 mt-3 border p-3 rounded-md">
                  {moreProducts.map((p, i) => {
                    const isLastElement = i === moreProducts.length - 1;
                    return (
                      <Link
                        to={`/product/details/${p.slug}`}
                        className={`block ${
                          isLastElement ? "" : "border-b border-gray-300"
                        }`}
                      >
                        <div className="relative h-[270px]">
                          <img
                            className="w-full h-full object-contain object-left"
                            src={p.images.split(",")[0]}
                            alt=""
                          />
                          {p.discount !== 0 && (
                            <div className="flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2">
                              {p.discount}%
                            </div>
                          )}
                        </div>
                        <h2 className="text-slate-600 py-1">{p.productName}</h2>
                        <div className="flex gap-2">
                          <h2 className="text-black text-lg font-bold">
                            ₹{p.sellingPrice}
                          </h2>
                          <div className="flex items-center gap-2">
                            <Ratings ratings={p.rating} />
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto rounded-md">
          <h2 className="text-2xl py-8 text-slate-600 rounded-md">
            Related Products
          </h2>
          <div>
            <Swiper
              slidesPerView="auto"
              breakpoints={{
                1280: {
                  slidesPerView: 3,
                },
                565: {
                  slidesPerView: 2,
                },
              }}
              spaceBetween={15}
              loop={true}
              pagination={{
                clickable: true,
                el: ".custom_bullet",
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {relatedProducts.map((p, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Link to={`/product/details/${p.slug}`} className="block">
                      <div className="relative h-[270px]">
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-contain object-left"
                            src={p.images.split(",")[0]}
                            alt=""
                          />
                          <div className="absolute h-full w-full top-0 left-0 bg-[#000] opacity-25 hover:opacity-50 transition-all duration-500"></div>
                        </div>
                        {p.discount !== 0 && (
                          <div className="flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2">
                            {p.discount}%
                          </div>
                        )}
                      </div>
                      <div className="p-4 flex flex-col gap-1">
                        <h2 className="text-slate-600 text-lg font-semibold">
                          {p.productName}
                        </h2>
                        <div className="flex justify-start items-center gap-3">
                          <h2 className="text-[#6699ff] text-lg font-bold">
                            ₹{p.sellingPrice}
                          </h2>
                          <div className="flex">
                            <Ratings ratings={p.rating} />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="w-full flex justify-center items-center py-10">
            <div className="custom_bullet justify-center gap-3 !w-auto"></div>
          </div>
        </div>
      </section>
      <Footer />      
    </div>
  );
};

export default Details;
