import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchModelsByBrand,
  fetchYearsByBrandAndModel,
  fetchVariantsByBrandModelAndYear,
  fetchSubVariantsByBrandModelYearAndVariant,
} from "../store/reducers/catalogueReducer";

const FindVehicleCatalogue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brands, models, years, variants, subVariants } = useSelector(
    (state) => state.catalogue
  );
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedSubVariant, setSelectedSubVariant] = useState("");

  const catalogue = () => {
    // Navigate to catalogueIndex.jsx page with selected values as URL parameters
    navigate(
      `/catalogueIndex/${selectedBrand}/${selectedModel}/${selectedYear}/${selectedVariant}/${selectedSubVariant}`
    );
  };

  const handleBrandChange = (event) => {
    const selectedBrand = event.target.value;
    setSelectedBrand(selectedBrand);
    dispatch(fetchModelsByBrand(selectedBrand));
  };

  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setSelectedModel(selectedModel);
    dispatch(
      fetchYearsByBrandAndModel({ brand: selectedBrand, model: selectedModel })
    );
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
    dispatch(
      fetchVariantsByBrandModelAndYear({
        brand: selectedBrand,
        model: selectedModel,
        year: selectedYear,
      })
    );
  };

  const handleVariantChange = (event) => {
    const selectedVariant = event.target.value;
    setSelectedVariant(selectedVariant);
    dispatch(
      fetchSubVariantsByBrandModelYearAndVariant({
        brand: selectedBrand,
        model: selectedModel,
        year: selectedYear,
        variant: selectedVariant,
      })
    );
  };

  const handleSubVariantChange = (event) => {
    const selectedSubVariant = event.target.value;
    setSelectedSubVariant(selectedSubVariant);
  };

  return (
    <div className="flex flex-wrap justify-center w-full bg-[#FFBE98]">
      {/* Input for Vehicle Number */}
      {/* <div className="flex items-center mb-2 mr-2">
        <input
          type="text"
          placeholder="Vehicle Number"
          className="w-full md:w-auto py-2 px-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      </div> */}

      {/* Search button */}
      {/* <div className="flex items-center mb-2 mr-2">
        <button className="w-full md:w-auto py-2 px-4 bg-orange-500 text-white rounded-md focus:outline-none focus:bg-blue-600">
          Search by number plate
        </button>
      </div> */}

      {/* Dropdowns */}
      <div className="flex items-center mb-2 mr-2">
        <label htmlFor="brandSelect" className="sr-only">
          Manufacturer
        </label>
        <select
          id="brandSelect"
          value={selectedBrand}
          onChange={handleBrandChange}
          className="w-full md:w-auto px-4 py-1 mt-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option>Manufacturer</option>
          {brands.map((brand, index) => (
            <option key={index}>{brand.brand}</option>
          ))}
        </select>
      </div>
      <div className="flex items-center mb-2 mr-2">
        <label htmlFor="modelSelect" className="sr-only">
          Model
        </label>
        <select
          id="modelSelect"
          value={selectedModel}
          onChange={handleModelChange}
          className="w-full md:w-auto px-4 py-1 mt-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option>Model</option>
          {models.map((model, index) => (
            <option key={index}>{model}</option>
          ))}
        </select>
      </div>
      <div className="flex items-center mb-2 mr-2">
        <label htmlFor="yearSelect" className="sr-only">
          Year
        </label>
        {/* Year dropdown */}
        <select
          id="yearSelect"
          value={selectedYear}
          onChange={handleYearChange}
          className="w-full md:w-auto px-4 py-1 mt-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option>Year</option>
          {years.map((year, index) => (
            <option key={index}>{year}</option>
          ))}
        </select>
      </div>
      <div className="flex items-center mb-2 mr-2">
        <label htmlFor="variantSelect" className="sr-only">
          Variant
        </label>
        {/* Variant dropdown */}
        <select
          id="variantSelect"
          value={selectedVariant}
          onChange={handleVariantChange}
          className="w-full md:w-auto px-4 py-1 mt-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option> Variant</option>
          {variants.map((variant, index) => (
            <option key={index}>{variant}</option>
          ))}
        </select>
      </div>
      <div className="flex items-center mb-2 mr-2">
        <label htmlFor="subVariantSelect" className="sr-only">
          Sub-Variant
        </label>
        {/* Sub-variant dropdown */}
        <select
          id="subVariantSelect"
          value={selectedSubVariant}
          onChange={handleSubVariantChange}
          className="w-full md:w-auto px-4 py-1 mt-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <option>Sub-Variant</option>
          {subVariants.map((subVariant, index) => (
            <option key={index}>{subVariant}</option>
          ))}
        </select>
      </div>
      {/* Search button */}
      <div className="flex items-center">
        <button
          onClick={catalogue}
          className="w-full md:w-auto px-2 py-1 bg-emerald-800 text-white rounded-md focus:outline-none focus:bg-blue-900"
        >
          Catalogue
        </button>
      </div>
    </div>
  );
};

export default FindVehicleCatalogue;
