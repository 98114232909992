import React from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";

const AboutUsPage = () => {
   return (
    <div className="bg-gray-100  md: lg: xl:">
      <Headers />
      <h1 className="text-3xl font-bold text-center mt-6 mb-6 bg-blue-800 text-white py-2">About Us</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center items-center">
          <img
             src={logo}
             alt="Company Logo"
            className="w-full h-auto mb-6 md:mb-0"
          />
        </div>
        <div className="flex flex-col justify-center items-start md:justify-start md:ml-4">
          <p className="text-lg mb-4">
                     We are an ecommerce platform specializing in automotive care
            products and services. Our mission is to provide a convenient and
            reliable solution for vehicle owners to find genuine and original
            spare parts at the right price.
          </p>
          <p className="text-lg mb-4">
                     At our store, we prioritize authenticity and affordability. We never
            charge over the Maximum Retail Price (MRP), ensuring that our
            customers get fair and transparent pricing.
          </p>
          <p className="text-lg mb-4">
                     We firmly believe that regular maintenance and proper servicing are
            essential for optimizing vehicle performance, increasing mileage,
            enhancing reliability, and prolonging the lifespan of your vehicle.
          </p>
          <p className="text-lg mb-4">
                     Our dedicated team is here to assist you every step of the way,
            whether you need assistance finding the right spare part, accessing
            expert advice, or scheduling maintenance services.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
