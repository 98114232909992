import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordRequest,
  messageClear,user_reset
} from "../../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import api from '../../api/api'
import { reset_count } from '../../store/reducers/cartReducer'
const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const loading = useSelector(state => state.changePassword.loading);
  const { userInfo, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );
  const customerId = userInfo.id;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track if passwords match
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Check if new password and retype new password match
    if (newPassword !== retypeNewPassword) {
      setPasswordsMatch(false);
      return; // Do not proceed further if passwords do not match
    }
    dispatch(
      changePasswordRequest({
        customerId,
        info: {
          oldPassword,
          newPassword,
        },
      })
    );
    // Reset form fields
    setOldPassword("");
    setNewPassword("");
    setRetypeNewPassword("");
  };
  const handleLogout = async () => {
    try {
      // Call logout API
      const { data } = await api.get('/customer/logout');
      // Clear local storage
      localStorage.removeItem('customerToken');
      // Dispatch user reset action
      dispatch(user_reset());
      // Reset any other state if needed
      dispatch(reset_count());
      // Navigate to the login page
      navigate('/login');
    } catch (error) {
      console.log(error.response.data);
    }
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      handleLogout(); // Call logout after displaying success message
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [dispatch, successMessage, errorMessage]);

  return (
    <div className="container max-w-full mx-auto py-24 px-6">
      <div className="font-sans">
        <div className="max-w-sm mx-auto px-6">
          <div className="relative flex flex-wrap">
            <div className="w-full relative">
              <div className="mt-6">
                <div className="text-center font-semibold text-black">
                  Change Password
                </div>

                <form onSubmit={handleFormSubmit} className="mt-8">
                  <div className="mx-auto max-w-lg">
                    <div className="py-2">
                      <span className="px-1 text-sm text-gray-600">
                        Old Password
                      </span>
                      <input
                        type="password"
                        id="old_password"
                        name="old_password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Old Password"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>
                    <div className="py-2 relative">
                      <span className="px-1 text-sm text-gray-600">
                        New Password
                      </span>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="new_password"
                          name="new_password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          placeholder="New Password"
                          className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        />
                        <span
                          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <svg
                              className="h-6 text-gray-700"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M18 12a6 6 0 11-12 0 6 6 0 0112 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="h-6 text-gray-700"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M18 12a6 6 0 11-12 0 6 6 0 0112 0z"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="py-2 relative">
                      <span className="px-1 text-sm text-gray-600">
                        Retype New Password
                      </span>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="retype_new_password"
                          name="retype_new_password"
                          value={retypeNewPassword}
                          onChange={(e) => setRetypeNewPassword(e.target.value)}
                          placeholder="Retype New Password"
                          className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        />
                        <span
                          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <svg
                              className="h-6 text-gray-700"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M18 12a6 6 0 11-12 0 6 6 0 0112 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="h-6 text-gray-700"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M18 12a6 6 0 11-12 0 6 6 0 0112 0z"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    {!passwordsMatch && (
                      <p className="text-red-500">
                        New password and retyped password do not match.
                      </p>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="mt-3 text-lg font-semibold bg-blue-600 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-blue-800"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
