import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Headers from "../components/Headers";
import Banner from "../components/Banner";
import Categories from "../components/Categories";
import FeaturedProducts from '../components/products/FeaturedProducts';
import Products from '../components/products/Products';
import Footer from '../components/Footer';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import FindVehicleCatalogue from"../components/findVehicleCatalogue";
// import ChatPopup from "../components/ChatPopup.jsx";

import { get_category, get_products} from '../store/reducers/homeReducer';
import { get_catalogue_brand  } from '../store/reducers/catalogueReducer.js';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Home = () => {
  const dispatch = useDispatch();  
  useEffect(() => {
    dispatch(get_category());
    dispatch(get_catalogue_brand());
  }, []);
  
  const { products, latestProduct,topRatedProduct,discountProduct, loading } = useSelector(state => state.home);

  useEffect(() => {
    // Dispatch the async thunk to get products
    dispatch(get_products());
  }, []);

  // Check if products are available before rendering
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color="#000" loading={loading} css={override} size={150} />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Headers />
      <div className="my-1"></div>
      <FindVehicleCatalogue/>
      <Banner />
      <div className="my-2">
        <Categories />
      </div>
      <div className="py-[45px]">
        {products.length > 0 ? (
          <FeaturedProducts products={products} />
        ) : (
          <div>No featured products available</div>
        )}
      </div>
      <div className='py-10'>
        <div className='w-[85%] flex flex-wrap mx-auto'>
          <div className="grid w-full grid-cols-3 md-lg:grid-cols-2 md:grid-cols-1 gap-7">
            <div className='overflow-hidden'>
              <Products title='Latest Product' products={latestProduct} />
            </div>
            <div className='overflow-hidden'>
              <Products title='Top Rated Product' products={topRatedProduct} />
            </div>
            <div className='overflow-hidden'>
              <Products title='Discount Product' products={discountProduct} />
            </div>
          </div>
        </div>
      </div>
      {/* <ChatPopup /> */}
      <Footer />
    </div>
  );
};

export default Home;
