import React, { useState, useEffect } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Msg91OTPWidget from "../components/Msg91OTPWidget";
import { Link, useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { useSelector, useDispatch } from "react-redux";
import {
  customer_login,
  customer_otp,
  messageClear,
} from "../store/reducers/authReducer";
import toast from "react-hot-toast";

const Login = () => {
  const { loader, successMessage, errorMessage, userInfo, mobileNumber } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
    mobile: "",
    otp: "",
    loginMethod: "mobile", // Default login method
  });

  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [otpTimer, setOtpTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (userInfo) {
      navigate("/");
    }
  }, [successMessage, errorMessage]);

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const toggleLoginMethod = () => {
    const newLoginMethod = state.loginMethod === "mobile" ? "email" : "mobile";
    setState({
      ...state,
      loginMethod: newLoginMethod,
    });
  };

  useEffect(() => {
    if (mobileNumber) {
      if (mobileNumber) {
        // Open OTP modal
        setShowOtpModal(true);
        setOtpTimer(5);
        setIsResendDisabled(false);
      }
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, mobileNumber]);

  const login = (e) => {
    e.preventDefault();
    dispatch(customer_login(state));
  };

  const handleOtpDigitChange = (index, value) => {
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);
  };

  const handleOtpSubmit = (data, phoneNumber) => {
    // Validate OTP and login if it's correct
    // const { otp, message, type } = data;

    // Now you can use otp, message, and type as needed
    // console.log("OTP:", otp);
    // console.log("Message:", message);
    // console.log("Type:", type);

    // Dispatch an action with the data
    dispatch(customer_otp({ data, phoneNumber }));
    setShowOtpModal(false);
  };

  const handleResendOtp = () => {
    // Resend OTP
    setOtpTimer(60);
    setIsResendDisabled(true);
    // Generate OTP and send it to the user's mobileNumber
  };

  useEffect(() => {
    let interval;
    if (showOtpModal) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
        if (otpTimer <= 0) {
          clearInterval(interval);
          setIsResendDisabled(true);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showOtpModal, otpTimer]);

  return (
    <div>
      <Headers />
      <div className="min-h-screen flex flex-col justify-center items-center">
        {loader && (
          <div className="w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]">
            <FadeLoader />
          </div>
        )}
        <div className="bg-slate-200 mt-4">
          <div className="w-full justify-center items-center p-10">
            <div className="px-8 py-8">
              <h2 className="text-center w-full text-xl text-slate-600 font-bold">
                Login
              </h2>
              <form onSubmit={login} className="text-slate-600">
                {state.loginMethod === "mobile" ? (
                  <>
                    <div className="flex flex-col gap-1 mb-2">
                      <label htmlFor="mobile">Mobile Number</label>
                      <input
                        onChange={inputHandle}
                        value={state.mobile}
                        type="text"
                        className="w-[300px] px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile Number"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-1 mb-2">
                      <label htmlFor="email">Email</label>
                      <input
                        onChange={inputHandle}
                        value={state.email}
                        type="email"
                        className="w-[300px] px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                        id="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="flex flex-col gap-1 mb-4">
                      <label htmlFor="password">Password</label>
                      <input
                        onChange={inputHandle}
                        value={state.password}
                        type="password"
                        className="w-[300px] px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md"
                        id="password"
                        name="password"
                        placeholder="password"
                      />
                    </div>
                  </>
                )}
                <button className="px-8 w-[300px] py-2 bg-purple-500 shadow-lg hover:shadow-indigo-500/30 text-white rounded-md">
                  Generate OTP
                </button>
              </form>
              <button
                onClick={toggleLoginMethod}
                className="text text-center w-full text-slate-500 mt-4 focus:outline-none hover:text-indigo-500 transition duration-300"
              >
                {state.loginMethod === "mobile"
                  ? "Login with Email/Password"
                  : "Login with Mobile Number/OTP"}
              </button>
              <div className="flex justify-center items-center py-2">
                <div className="h-[1px] bg-slate-300 w-[95%]"></div>
                <span className="px-3 text-slate-600">or</span>
                <div className="h-[1px] bg-slate-300 w-[95%]"></div>
              </div>
            </div>
            <div className="text-center text-slate-600 pt-1">
              <p>
                You have no Account ?{" "}
                <Link className="text-blue-500" to="/register">
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showOtpModal && (
        <Msg91OTPWidget
          mobileNumber={mobileNumber}
          otpDigits={otpDigits}
          handleOtpDigitChange={handleOtpDigitChange}
          handleResendOtp={handleResendOtp}
          otpTimer={otpTimer}
          isResendDisabled={isResendDisabled}
          handleOtpSubmit={handleOtpSubmit}
          setShowOtpModal={setShowOtpModal}
        />
      )}
    </div>
  );
};

export default Login;
