import React, { useEffect } from "react";
import Ratings from "../Ratings";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  get_wishlist_products,
  add_to_cart,
  remove_wishlist,
  messageClear,
} from "../../store/reducers/cartReducer";
import toast from "react-hot-toast";
const Wishlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { wishlist, successMessage } = useSelector((state) => state.cart);
  const add_cart = (id) => {
    dispatch(
      add_to_cart({
        userId: userInfo.id,
        quantity: 1,
        productId: id,
      })
    );
  };
  useEffect(() => {
    dispatch(get_wishlist_products(userInfo.id));
  }, []);
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      dispatch(get_wishlist_products(userInfo.id));
    }
  }, [successMessage]);
  return (
    <div className="w-full grid grid-cols-4 md-lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6">
      {wishlist.map((p, i) => (
        <div
          key={i}
          className="border group transition-all duration-500 hover:shadow-md hover:-mt-3 bg-white"
        >
          <div className="relative overflow-hidden">
            {parseFloat(p.discount) !== 0 && (
              <div className="flex justify-center items-center absolute text-white w-[50px] h-[50px] rounded-full bg-yellow-400 font-semibold text-xs left-2 top-2">
                {p.discount}%
              </div>
            )}

            <img
              className="sm:w-full w-full object-contain object-center"
              src={p.image.split(",")[0]}
              alt="product image"
            />

            <ul className="flex transition-all duration-700 -bottom-10 justify-center items-center gap-2 absolute w-full group-hover:bottom-3">
              <Link
                to={`/product/details/${p.slug}`}
                className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
              >
                <FaEye />
              </Link>
            </ul>
          </div>
          <div className="py-3 text-slate-600 px-2">
            <h2>{p.name}</h2>
            <div className="flex justify-start items-center gap-3">
              <span className="text-lg  font-bold">₹{p.price}</span>
              <div className="flex">
                <Ratings ratings={p.rating} />
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <div>
              <button
                onClick={() => {
                  dispatch(remove_wishlist(p.idwishlists));
                }}
                className="px-5 py-[3px] bg-red-500 text-white rounded-full mr-4"
              >
                Remove from Wishlist
              </button>
            </div>
            <div>
              <button
                onClick={() => add_cart(p.productId)}
                className="flex items-center px-5 py-[3px] bg-yellow-500 text-black rounded-full"
              > <AiOutlineShoppingCart className="mr-2" />
                Add to Cart
              </button>

            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Wishlist;
