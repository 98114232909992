import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AiOutlineShoppingCart, AiFillHeart } from 'react-icons/ai'
import logo from "./../assets/logo.png";

const Footer = () => {
    const { cart_product_count, wishlist_count } = useSelector(state => state.cart)
    const navigate = useNavigate()
    const { userInfo } = useSelector(state => state.auth)

    return (
        <footer className='bg-[#F3F6Fa] min-h-[200px]'>
            <div className='w-[85%] flex flex-wrap mx-auto border-b py-5 md-lg:pb-10 sm:pb-6'>
                <div className='w-3/12 lg:w-4/12 sm:w-full'>
                    <div className='flex flex-col gap-3'>
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Company Logo"
                                style={{ width: "224px", height: "70px" }} // Explicit width and height
                            />
                        </Link>
                    </div>
                </div>
                <div className='w-5/12 lg:w-8/12 sm:w-full'>
                    <div className='flex justify-center sm:justify-start sm:mt-6 w-full'>
                        <div>
                            <h2 className='font-bold text-lg mb-2'>Get to Know Us</h2>
                            <div className='flex justify-between gap-[80px] lg:gap-[40px]'>
                                <ul className='flex flex-col gap-2 text-slate-600 text-sm'>
                                    <li>
                                        <Link to="/aboutus">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/deliveryinformation">Delivery And Refund Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacypolicy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[85%] flex flex-wrap justify-center items-center text-slate-600 mx-auto text-center'>
                <span>Copyright ©2024 All rights reserved </span>
            </div>
            <div className='hidden fixed md-lg:block w-[50px] bottom-3 h-[110px] right-2 bg-white rounded-full p-2'>
                <div className='w-full h-full flex gap-3 flex-col justify-center items-center'>
                    <div onClick={() => navigate(userInfo ? '/cart' : '/login')} className='relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]'>
                        <span className='text-xl text-blue-700'><AiOutlineShoppingCart /></span>
                        {
                            cart_product_count !== 0 && <div className='w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]'>
                                {
                                    cart_product_count
                                }
                            </div>
                        }
                    </div>
                    <div onClick={() => navigate(userInfo ? '/dashboard/my-wishlist' : '/login')} className='relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]'>
                        <span className='text-xl text-red-500'><AiFillHeart /></span>
                        {
                            wishlist_count !== 0 && <div className='w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]'>
                                {wishlist_count}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
