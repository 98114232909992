import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'

export const get_catalogue_brand = createAsyncThunk(
  'catalogue/get_catalogue_brand',
  async (_, { rejectWithValue, fulfillWithValue }) => {
      try {
          const {
              data
          } = await api.get('/catalogue/get-brand')
          return fulfillWithValue(data)
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
  }
)


export const fetchModelsByBrand = createAsyncThunk(
    'catalogue/fetch_Models',
    async (brandName, { rejectWithValue, fulfillWithValue }) => {        
      try {
        const { data } = await api.get(`/catalogue/models/${brandName}`);
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )
  export const fetchYearsByBrandAndModel = createAsyncThunk(
    'catalogue/fetch_Years',
    async ({ brand, model }, { rejectWithValue, fulfillWithValue }) => {        
      try {
        const { data } = await api.get(`/catalogue/year/${brand}/${model}`);
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )
  export const fetchVariantsByBrandModelAndYear = createAsyncThunk(
    'catalogue/fetch_variant',
    async ({ brand, model, year }, { rejectWithValue, fulfillWithValue }) => {        
      try {
        const { data } = await api.get(`/catalogue/variant/${brand}/${model}/${year}`);
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )
  export const fetchSubVariantsByBrandModelYearAndVariant  = createAsyncThunk(
    'catalogue/fetch_sub-variant',
    async ({ brand, model, year, variant }, { rejectWithValue, fulfillWithValue }) => {               
      try {
        const { data } = await api.get(`/catalogue/sub-variant/${brand}/${model}/${year}/${variant}`);
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )

   export const get_group  = createAsyncThunk(
    'catalogue/get_group',
    async ({ brand, model, year, variant, subVariant }, { rejectWithValue, fulfillWithValue }) => {               
      try {
        const { data } = await api.get(`/catalogue/get-groups/${brand}/${model}/${year}/${variant}/${subVariant}`);
        
        return fulfillWithValue(data);  
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )  
  export const getCatalogue  = createAsyncThunk(
    'catalogue/get_catalogue',
    async ({ brand, model, year, variant, subVariant, idcatalogue }, { rejectWithValue, fulfillWithValue }) => {               
      try {
        const { data } = await api.get(`/catalogue/get-catalogue/${brand}/${model}/${year}/${variant}/${subVariant}/${idcatalogue}`);
        
        return fulfillWithValue(data);  
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  )  
  


export const catalogueReducer = createSlice({
    name: 'catalogue',
    initialState: {       
        brands:[],
        models:[],
        years: [],
        variants: [],
        subVariants: [],
        groups:[],
        catalogue: [],
        parts: [],
        successMessage: '',
        errorMessage: '',
    },
    reducers: {
        messageClear: (state, _) => {
            state.successMessage = ""
            state.errorMessage = ""
        }
    },
    extraReducers: builder => {
        builder            
            .addCase(get_catalogue_brand.fulfilled, (state, { payload }) => {               
                state.brands = payload.brands;
            })
            .addCase(fetchModelsByBrand.fulfilled, (state, { payload }) => {               
                state.models = payload.models;
            })
            .addCase(fetchYearsByBrandAndModel.fulfilled, (state, { payload }) => {               
                state.years = payload.years;
            })
            .addCase(fetchVariantsByBrandModelAndYear.fulfilled, (state, { payload }) => {               
                state.variants = payload.variants;
            })
            .addCase(fetchSubVariantsByBrandModelYearAndVariant.fulfilled, (state, { payload }) => {               
                state.subVariants = payload.subVariants;
            })
            .addCase(get_group.fulfilled, (state, { payload }) => {               
                state.groups = payload.groups;
            })
            .addCase(getCatalogue.fulfilled, (state, { payload }) => {               
              state.catalogue = payload.catalogue;
              state.parts = payload.parts;

          });
           
    }
})
export const {
    messageClear
} = catalogueReducer.actions
export default catalogueReducer.reducer