import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { get_order } from "../../store/reducers/orderReducer";
import {
  submit_return_request,
  get_return,
  messageClear,
} from "../../store/reducers/orderReducer";

const ReturnOrder = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate function
  const { myOrder } = useSelector((state) => state.order);
  const { userInfo } = useSelector((state) => state.auth);
  const { returnProducts } = useSelector((state) => state.order);
  const [selectedItems, setSelectedItems] = useState([]);
  const [returnReason, setReturnReason] = useState("");
  const [totalReturnAmount, setTotalReturnAmount] = useState(0); // Total return amount state
  const { successMessage, errorMessage } = useSelector((state) => state.order);

  const formattedDate =
    myOrder && myOrder.createdAt ? new Date(myOrder.createdAt) : null;
  // Check if formattedDate is not null and is a valid Date object
  const displayDate =
    formattedDate instanceof Date && !isNaN(formattedDate)
      ? `${formattedDate.getDate()}-${formattedDate.toLocaleString("default", {
          month: "short",
        })}-${formattedDate.getFullYear()}`
      : "";
  // Get the time portion
  const displayTime =
    formattedDate instanceof Date && !isNaN(formattedDate)
      ? formattedDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "";
  useEffect(() => {
    dispatch(get_order(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(get_return(orderId));
  }, [dispatch, orderId]);

  const shippingInfo =
    myOrder && myOrder.shippingInfo ? JSON.parse(myOrder.shippingInfo) : null;

    const handleItemSelection = (productId, sellerId, sellingPrice, discount, quantity) => {
      setSelectedItems((prevSelectedItems) => {
        const itemIndex = prevSelectedItems.findIndex(item => item.productId === productId && item.sellerId === sellerId);
        if (itemIndex !== -1) {
          // If the item is already selected, remove it
          return prevSelectedItems.filter((item, index) => index !== itemIndex);
        } else {
          // If the item is not selected, add it
          return [...prevSelectedItems, { productId, sellerId, quantity, discount, sellingPrice: sellingPrice - Math.floor(((sellingPrice * discount) / 100) * quantity)+100 }];//100 is for shipping price
        }
      });
    };
    

  const handleReasonChange = (event) => {
    setReturnReason(event.target.value);
  };

  useEffect(() => {
    let totalAmount = 0;
    selectedItems.forEach(({ productId, sellerId }) => {
      const selectedItem = myOrder.products.find(
        (item) => item.productId === productId && item.sellerId === sellerId
      );
      if (selectedItem) {
        totalAmount +=
          selectedItem.sellingPrice * selectedItem.quantity -
          Math.floor(
            (selectedItem.sellingPrice * selectedItem.discount) / 100
          ) +
          100;
      }
    });
    setTotalReturnAmount(totalAmount);
  }, [selectedItems, myOrder.products]);
  

  const handleSubmit = () => {
    dispatch(
      submit_return_request({
        orderId,
        selectedItems,
        returnReason,
        shippingInfo,
        customerId: userInfo.id,
        totalReturnAmount,
      })
      
    );
    // Reset the form state
    setSelectedItems([]);
    setReturnReason("");
    setTotalReturnAmount(0);
   };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      // Navigate to dashboard
      navigate("/dashboard");
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  return (
    <div className="bg-white p-5 rounded-md">
      <h2 className="text-black bg-slate-300 font-semibold">
        # Order Id {myOrder?.idorders} ,{" "}
        <span className="pl-1">
          {displayDate} {displayTime}
        </span>
      </h2>

      <div className="grid grid-cols-2 gap-3">
        <div className="bg-yellow-200 p-4 rounded-lg">
          <div className="flex flex-col gap-1">
            <h2 className="text-black font-semibold">
              Pickup From: {shippingInfo?.name}
            </h2>
            <p className="text-slate-600 text-sm">
              {shippingInfo?.address} <br />
              {shippingInfo?.district} <br />
              {shippingInfo?.city} <br />
              {shippingInfo?.area} <br />
              {shippingInfo?.utstate} <br />
              {shippingInfo?.pin} <br />
              {shippingInfo?.landmark}
            </p>
          </div>
        </div>

        <div className="text-black font-semibold">
          <h2>Total Purchase Amount: ₹{myOrder?.price} Shipping Fee Include </h2>
          <p>
            Payment status:{" "}
            <span
              className={`py-[1px] text-xs px-3 ${
                myOrder?.payment_status === "Paid"
                  ? "bg-green-300 text-black"
                  : "bg-red-300 text-black"
              } rounded-md `}
            >
              {myOrder?.payment_status}
            </span>
          </p>
          <p>
            Order status:{" "}
            <span
              className={`py-[1px] text-xs px-3 ${
                myOrder?.delivery_status === "Processing"
                  ? "bg-green-300 text-black"
                  : "bg-red-300 text-black"
              } rounded-md `}
            >
              {myOrder?.delivery_status}
            </span>
          </p>
        </div>
      </div>
      <div className="mt-3">
        <h2 className="text-slate-600 text-lg pb-2">Products</h2>
        <div className="flex gap-5 flex-col">
          {myOrder.products?.map((p, i) => (
            <div
              key={i}
              className="flex justify-start items-center text-black border-b"
            >
              <div
                className="flex gap-2 items-center"
                style={{ width: "1200px" }}
              >
                {" "}
                {/* Added inline style for width */}
                <input
                  type="checkbox"
                  checked={selectedItems.some(item => item.productId === p.productId)}
                  onChange={() => handleItemSelection(p.productId, p.sellerId, p.sellingPrice, p.discount, p.quantity)}
                  className={`mr-2 ${
                    returnProducts &&
                    returnProducts
                      .map((item) => item.productId)
                      .includes(p.productId)
                      ? "hidden"
                      : ""
                  }`}
                />
                <img
                  className="w-[80px] h-[80px]"
                  src={p.images.split(",")[0]}
                  alt="image"
                />
                <div className="flex flex-col justify-start">
                  {" "}
                  {/* Removed items-start */}
                  <Link className="hover:text-blue-500 hover:shadow-md">
                    {p.productName}
                  </Link>
                  <p>
                    <span>Brand: {p.brand}</span>
                  </p>
                  <p>
                    <span>Quantity: {p.quantity}</span>
                  </p>
                  <p>Seller Id : {p.sellerId}</p>
                </div>
              </div>
              <div
                className="pl-4 flex flex-col justify-between"
                style={{ width: "200px" }}
              >
                {" "}
                {/* Added inline style for width */}
                {p.discount === 0 ? (
                  <h2 className="text-md text-orange-500">₹{p.sellingPrice}</h2>
                ) : (
                  <div>
                    <h2 className="text-md text-orange-500">
                      ₹
                      {p.sellingPrice -
                        Math.floor((p.sellingPrice * p.discount) / 100)}
                    </h2>
                    <p>₹{p.mrp}</p>
                    <p>-{p.discount}%</p>
                  </div>
                )}
              </div>
              {selectedItems.includes(p.productId) && ( // Only display if the item is selected
                <div
                  className="pl-4 flex flex-col justify-between"
                  style={{ width: "200px" }}
                >
                  {p.discount === 0 ? (
                    <h2 className="text-md text-orange-500">
                      ₹{p.sellingPrice}
                    </h2>
                  ) : (
                    <div>
                      <h2 className="text-md text-orange-500">
                        ₹
                        {p.sellingPrice -
                          Math.floor((p.sellingPrice * p.discount) / 100)}
                      </h2>
                    </div>
                  )}
                </div>
              )}
              {returnProducts &&
                returnProducts.map((item) =>
                  item.productId === p.productId &&
                  item.returnStatus !== null ? (
                    <div
                      key={item.productId}
                      className="pl-4 flex flex-col justify-between text-green-700 font-semibold"
                      style={{ width: "200px" }}
                    >
                      <p>Return {item.returnStatus}</p>
                    </div>
                  ) : null
                )}
            </div>
          ))}

          <div className="font-semibold mb-2 border-b">
            {" "}
            Total Return Amount (Including Shipping Fees) : ₹{totalReturnAmount}
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="returnReason" className="block font-semibold mb-1">
            Select reason for return:
          </label>
          <select
            id="returnReason"
            value={returnReason}
            onChange={handleReasonChange}
            className="border p-2 rounded-md w-full"
          >
            <option value="">Select a reason</option>
            <option value="Defective product">Defective product</option>
            <option value="Wrong item received">Wrong item received</option>
            <option value="Not satisfied with the product">
              Not satisfied with the product
            </option>
            {/* Add more options as needed */}
          </select>
        </div>

        {/* Button to submit the form */}
        <button
          onClick={handleSubmit}
          disabled={selectedItems.length === 0 || !returnReason}
          className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:bg-gray-400"
          required
        >
          Submit Return
        </button>
      </div>
    </div>
  );
};

export default ReturnOrder;
