import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { get_banners, get_bannerproducts } from "../store/reducers/homeReducer";
const Banner = () => {
  const dispatch = useDispatch(); 
  const { banners } = useSelector((state) => state.home);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    dispatch(get_banners());
  }, []);

  const handleBannerClick = (bannerId) => {    
    dispatch(get_bannerproducts(bannerId));
  };
  // Helper function to determine if an element should be aria-hidden
  const shouldBeHidden = (index) => {
    return index !== 0; // Example condition, adjust as needed
  };
  
  return (
    <div className="w-full md-lg:mt-6">
      <div className="w-[85%] lg:w-[90%] mx-auto">
        <div className="w-full flex flex-wrap md-lg:gap-8">
          <div className="w-full">
            <div className="my-8">
              <Carousel
                autoPlay={true}
                infinite={true}
                arrows={true}
                showDots={true}
                responsive={responsive}
              >
                {banners.length > 0 &&
                  banners.map((b, i) => (
                    <Link
                      className="lg-md:h-[440px] h-full w-full block object-contain object-center"
                      key={i}
                      to={`/product/offer/${b.idbanners}`}
                      onClick={() => handleBannerClick(b.idbanners)} // Pass banner id to the handler
                      aria-hidden={shouldBeHidden(i) ? "true" : "false"}
                      tabIndex={shouldBeHidden(i) ? "-1" : "0"}
                    >
                      <img src={b.image} alt={b.name} />
                    </Link>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
