import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  messageClear,
  get_questions,
  ask_question,
} from "../store/reducers/homeReducer";
import toast from "react-hot-toast";

const Questions = ({ product }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage, errorMessage, questions, totalQuestions } =
    useSelector((state) => state.home);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [question, setQuestion] = useState("");

  const question_submit = (e) => {
    e.preventDefault();
    const obj = {
      customerId: userInfo.id,
      name: userInfo.name,
      question,
      productId: product.idproducts,
    };
    dispatch(ask_question(obj));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(get_questions({ productId: product.idproducts, pageNumber }));
      setQuestion("");
      dispatch(messageClear());
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (product.idproducts) {
      dispatch(get_questions({ productId: product.idproducts, pageNumber }));
    }
  }, [pageNumber, product]);

  return (
    <div className="mt-8">
      <h2 className="text-slate-600 text-xl font-bold pb-5">
        Product Questions ({totalQuestions})
      </h2>
      {questions.length > 0 ? ( // Conditionally render questions if there are any
        <div className="flex flex-col gap-4 pb-10">
          {questions.map((q, i) => (
            <div key={i} className="border p-4 rounded-md">
              <div className="flex justify-between items-center mb-2">
                <span className="text-lg font-semibold border rounded-md bg-yellow-200 px-4">
                  {q.customerName}
                </span>
              </div>
              <p className="text-gray-700">{q.question}</p>
              {q.answer && (q.idseller !== null || q.idadmin !== null) && (
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-lg font-semibold border rounded-md bg-green-200 px-4">
                      {q.idadmin !== null ? "Admin" : "Seller"}{" "}
                      </span>
                  </div>
                  <p className="text-gray-700">{q.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-700">No questions yet.</p>
      )}
      <div>
        {userInfo ? (
          <div className="flex flex-col gap-3">
            <form onSubmit={question_submit}>
              <textarea
                value={question}
                required
                onChange={(e) => setQuestion(e.target.value)}
                className="border outline-none p-3 w-full rounded-md"
                name="question"
                id="question"
                rows="3"
                placeholder="Ask your question here..."
              ></textarea>
              <div className="mt-2">
                <button className="py-2 px-4 mb-2 bg-blue-500 text-white rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <Link
              className="py-2 px-4 bg-indigo-500 text-white rounded-md"
              to="/login"
            >
              Login to Ask
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
