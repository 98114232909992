import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Categories = () => {
  const { categories } = useSelector((state) => state.home);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 3,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 2,
    },
  };
  return (
    <div className="w-[87%] mx-auto relative">
      {/* Category Carousel */}
      <Carousel
        autoPlay={true}
        infinite={true}
        arrows={true}
        responsive={responsive}
        transitionDuration={500}
      >
          {categories.map((c, i) => (
          <div
            key={i}
            className="border rounded-md flex flex-col"
            aria-hidden={true}
            style={{ marginRight: "10px" }}
          >
            <Link
              to={`/products?category=${c.name}`}
              tabIndex="-1"
              className="flex flex-col h-full w-full"
            >
              <div className="h-[160px] relative p-1">
                <img
                  src={c.image}
                  alt="Category Image"
                  className="w-full h-full object-contain rounded-t-md"
                />
              </div>
              <div className="h-[35px] relative bg-[#ff7f16b9] flex justify-center items-center rounded-b-md">
                <span className="font-bold text-black">{c.name}</span>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default Categories;
