import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
const Products = ({ title, products }) => {
  if (!products || !Array.isArray(products) || products.length === 0) {
    // Handle the case when products is undefined or not an array
    return null; // or display a loading message, an empty state, etc.
  }
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-xl font-bold text-black">{DOMPurify.sanitize(title)}</div>
        <div className="flex justify-center items-center gap-3 text-black">
          <button
            onClick={() => previous()}
            className="w-[30px] h-[30px] flex justify-center items-center bg-slate-300 border border-slate-200"
            aria-label="Previous"
          >
            <span>
              <FiChevronLeft />
            </span>
          </button>
          <button
            onClick={() => next()}
            className="w-[30px] h-[30px] flex justify-center items-center bg-slate-300 border border-slate-200"
            aria-label="Next"
          >
            <span>
              <FiChevronRight />
            </span>
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="flex gap-8 flex-col-reverse">      
      <Carousel
        autoPlay={false}
        infinite={false}
        arrows={false}
        responsive={responsive}
        transitionDuration={500}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
       {products.map((p, i) => (
      <div key={i} className="flex flex-col gap-2">
        {p.map((pl, j) => (
          <Link
            key={j}
            className="flex"
            to={`/product/details/${pl.slug}`}
          >
            <img
              className="w-[110px] object-contain"
              src={pl.images.split(",")[0]}
              alt="images"
            />
            <div className="px-3 flex flex-col font-semibold text-slate-800">
              <h2>{DOMPurify.sanitize(pl.productName)}</h2>
              <span className="mt-2">Brand: {DOMPurify.sanitize(pl.brand)}</span>
              <span className="text-lg font-bold">₹{DOMPurify.sanitize(pl.sellingPrice)}</span>
            </div>
          </Link>
        ))}
      </div>
    ))}
  </Carousel>
</div>
  );
};

export default Products;
